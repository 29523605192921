import Vue from 'vue'
import Router from 'vue-router'
import firebase from 'firebase/app'
import 'firebase/auth'
import Overview from '@/views/Overview.vue'
import User from '@/views/user/User.vue'
import UserDetail from '@/views/user/UserDetail.vue'
import UserReport from '@/views/UserReport.vue'
import Cashout from '@/views/cashout/Cashout.vue'
import Topup from '@/views/topup/Topup.vue'
import AdminTopup from '@/views/topup/AdminTopup.vue'
import Podcast from '@/views/podcast/Podcast.vue'
import PodcastDetail from '@/views/podcast/PodcastDetail.vue'
import Live from '@/views/live/Live.vue'
import LiveDetail from '@/views/live/LiveDetail.vue'
import NotFoundPage from '@/views/NotFound'
import Forbidden from '@/views/Forbidden'
import Login from '@/views/Login.vue'
import Settings from '@/views/Settings.vue'
// Store
import store from '@/store'
// Overview sections
import Annoucement from '@/views/overview/Annoucement.vue'
import Gift from '@/views/overview/Gift.vue'

Vue.use(Router)

const router = new Router({
  // scrollBehavior: () => ({ y: 0 }),
  mode: 'history',
  linkActiveClass: 'is-active',
  routes: [
    {
      path: '/',
      redirect: '/live'
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: {
        title: 'Login'
      }
    },
    {
      path: '/users',
      name: 'users',
      component: User,
      meta: {
        title: 'Manage User',
        agencyAccess: false
      }
    },
    {
      path: '/users/:id',
      name: 'userDetail',
      component: UserDetail,
      meta: {
        title: 'User Detail',
        agencyAccess: false
      }
    },
    {
      path: '/overview',
      name: 'overview',
      component: Overview,
      children: [
        {
          path: 'annoucement',
          name: 'annoucement',
          component: Annoucement
        },
        {
          path: 'gift',
          name: 'gift',
          component: Gift
        }
      ],
      meta: {
        title: 'Overview',
        agencyAccess: true
      }
    },
    {
      path: '/podcasts',
      name: 'podcasts',
      component: Podcast,
      meta: {
        title: 'Podcast Feed',
        agencyAccess: false
      }
    },
    {
      path: '/podcasts/:id',
      name: 'podcastDetail',
      component: PodcastDetail,
      props: true,
      meta: {
        title: 'Podcast Detail',
        agencyAccess: false
      }
    },
    {
      path: '/live',
      name: 'live',
      component: Live,
      meta: {
        title: 'Manage Live',
        agencyAccess: true
      }
    },
    {
      path: '/live/:id',
      name: 'liveDetail',
      component: LiveDetail,
      props: true,
      meta: {
        title: 'Live Detail',
        agencyAccess: true
      }
    },
    {
      path: '/cashout',
      name: 'cashout',
      component: Cashout,
      meta: {
        title: 'Cashout User',
        agencyAccess: false,
        adminOnly: true
      }
    },
    {
      path: '/topup',
      name: 'topup',
      component: Topup,
      meta: {
        title: 'Topup',
        agencyAccess: true
      }
    },
    {
      path: '/admin-topup',
      name: 'adminTopup',
      component: AdminTopup,
      meta: {
        title: 'Admin Topup',
        agencyAccess: false,
        adminOnly: true
      }
    },
    {
      path: '/report',
      name: 'userReport',
      component: UserReport,
      meta: {
        title: 'User Report',
        agencyAccess: false
      }
    },
    {
      path: '/about',
      name: 'about',
      meta: {
        title: 'About',
        agencyAccess: true
      },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '@/views/About.vue')
    },
    {
      path: '/forbidden',
      name: 'forbidden',
      component: Forbidden,
      meta: {
        agencyAccess: true
      }
    },
    {
      path: '/settings',
      name: 'settings',
      component: Settings,
      meta: {
        agencyAccess: true
      }
    },
    {
      path: '*',
      name: '404',
      component: NotFoundPage,
      meta: {
        agencyAccess: true
      }
    }
  ]
})

// Check user authenticated
router.beforeEach(async (to, from, next) => {
  const currentUser = store.getters.user || firebase.auth().currentUser
  const requiresAdmin = to.matched.some(record => record.meta.adminOnly)
  const agencyAccess = to.matched.some(record => record.meta.agencyAccess)
  const isAgency = store.getters.user && store.getters.user.activeRole ? store.getters.user.activeRole === 'agency' : false
  const isAdmin = store.getters.role ? store.getters.role.includes('admin') : false
  // Set page title
  document.title = to.meta.title
  if (firebase.auth().currentUser) {
    const token = await firebase.auth().currentUser.getIdToken(true)
    localStorage.setItem('firebase-token', token)
  }
  // Verify that the user isn't logged in
  if (!currentUser && to.path !== '/login') {
    next('/login')
  } else if (requiresAdmin && !isAdmin) {
    next('/forbidden') // If no permission redirect to forbidden page
  } else if (isAgency && agencyAccess === false) {
    next('/forbidden') // If no permission redirect to forbidden page
  } else {
    next()
  }
})

export default router
