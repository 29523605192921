export const post = async (url, body) => {
  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('firebase-token')
    },
    body: JSON.stringify(body)
  })
  const data = await response.json() // parses JSON response into native JavaScript objects
  return data
}

export const get = async (url) => {
  const response = await fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('firebase-token')
    }
  })
  const data = await response.json() // parses JSON response into native JavaScript objects
  return data
}
