<template>
  <section class="section">
    <div class="container">
      <h1 class="is-size-3 has-text-white">
        Settings
      </h1>
      <section>
        <div class="card">
          <div class="card-content">
            <h3>Push Notification</h3>
            <p class="content">
              Change how to recieve push notification from user activity.
            </p>
            <div class="columns">
              <div class="column">
                <div class="field">
                  <b-switch
                    v-model="reportNotification"
                    :disabled="!isRegistered"
                    @input="reportNotifyChanged"
                  >
                    New Report Alert
                  </b-switch>
                </div>
              </div>
              <div class="column">
                <b-switch
                  v-model="uploadNotification"
                  :disabled="true"
                >
                  New Content Upload
                </b-switch>
              </div>
              <div class="column">
                <b-switch
                  v-model="cashoutNotification"
                  :disabled="!isRegistered || role.includes('moderator')"
                >
                  Cashout Alert (Admin only)
                </b-switch>
              </div>
            </div>
          </div>
          <hr class="dropdown-divider">
          <div class="card-content">
            <h3>User Profile</h3>
            <p class="content">
              All about user information in console
            </p>
            <div class="columns">
              <div class="column">
                <ul>
                  <li><strong>UID:</strong> {{ user.uid }}</li>
                  <li><strong>Tag Name:</strong> {{ profile ? profile[0].tag_name : '' }}</li>
                  <li><strong>Display Name:</strong> {{ profile ? profile[0].display_name : '' }}</li>
                </ul>
              </div>
              <div class="column">
                <ul>
                  <li>
                    <strong>E-mail:</strong> {{ user.email }} <span v-if="user.emailVerified">(Verified)</span><span v-else><a
                      href="#"
                      @click="verifyEmail"
                    >Verify Now</a></span>
                  </li>
                  <li><strong>Sign in method:</strong> {{ user.providerId.map((data) => data.providerId).join(', ') }}</li>
                  <li><strong>User Role:</strong> {{ role.join(', ') }}</li>
                </ul>
              </div>
            </div>
          </div>
          <hr class="dropdown-divider">
          <div class="card-content">
            <h3>Device Registration</h3>
            <p class="content">
              Register this device to activate notification channel
            </p>
            <section class="content">
              <b-button
                v-if="!isRegistered"
                :disabled="!isPushSupported"
                type="is-success"
                rounded
                size="is-medium"
                @click="registerDeviceToken"
              >
                {{ isPushSupported ? 'Register this device' : 'Browser not support' }}
              </b-button>
              <b-button
                v-if="isRegistered"
                :disabled="!isPushSupported"
                type="is-danger"
                outlined
                rounded
                size="is-medium"
                @click="unregisterDeviceToken"
              >
                {{ isPushSupported ? 'Unregister this device' : 'Browser not support' }}
              </b-button>
            </section>
          </div>
          <hr class="dropdown-divider">
          <div class="card-content">
            <h3>Authentication</h3>
            <p class="content">
              Link other sign in provider credentials to an existing account
            </p>
            <div class="columns">
              <div
                v-if="currentUser && currentUser.providerData[0].providerId === 'password'"
                class="column"
              >
                <b-button
                  rounded
                  size="is-medium"
                  @click="linkWithFacebook"
                >
                  Link this account with Facebook
                </b-button>
              </div>
              <div
                v-if="currentUser && currentUser.providerData.length === 1 && currentUser.providerData[0].providerId === 'facebook.com'"
                class="column"
              >
                <b-button
                  rounded
                  size="is-medium"
                  @click="openLoginDialog"
                >
                  Link this account with E-mail
                </b-button>
              </div>
              <div
                v-if="currentUser && currentUser.providerData.length > 1 && currentUser.providerData[1].providerId === 'password'"
                class="column"
              >
                <b-button
                  rounded
                  size="is-medium"
                  @click="unlinkAccount('password')"
                >
                  Unlink E-mail account
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <b-modal
      :active.sync="loginModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
    >
      <login-modal
        @loginClick="linkWithEmail"
      />
    </b-modal>
  </section>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import firebase from 'firebase/app'

import getMyProfile from '@/graphql/getMyProfile.gql'
import getFCMToken from '@/graphql/getUserFCMToken.gql'
import registerFCMToken from '@/graphql/registerFCMToken.gql'
import unRegisterFCMToken from '@/graphql/unregisterFCMToken.gql'

export default {
  name: 'Settings',
  components: {
    LoginModal: () => import('@/components/login/LoginModal.vue')
  },
  data () {
    return {
      token: null,
      isRegistered: false,
      tokenDetail: {
        claims: {
          'https://hasura.io/jwt/claims': {
            'x-hasura-allowed-roles': []
          }
        }
      },
      reportNotification: localStorage.getItem('report_notification') || false,
      facebookToken: null,
      loginModalActive: false,
      uploadNotification: false,
      cashoutNotification: false
    }
  },
  apollo: {
    profile: {
      query: getMyProfile
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      role: 'role'
    }),
    isPushSupported () {
      return ('Notification' in window)
    },
    currentUser () {
      return firebase.auth().currentUser
    }
  },
  async created () {
    if (firebase.messaging.isSupported()) {
      Vue.prototype.$messaging.getToken().then(async (token) => {
        this.token = token
        this.isRegistered = await this.getDeviceToken(token)
      })
    }
    try {
      const { user, credential } = await firebase.auth().getRedirectResult()
      if (credential) {
        this.facebookToken = credential.accessToken
        this.loginModalActive = true
      }
      if (user) {
        await this.$store.dispatch('signInAction', user)
      }
    } catch (error) {
      this.showError(error.message)
    }
  },
  methods: {
    async verifyEmail () {
      try {
        await firebase.auth().currentUser.sendEmailVerification()
        this.showToastMessage('Verification link has been send to your E-mail', 'is-info')
      } catch (error) {
        alert(error)
      }
    },
    subscribeTopic (topic) {
      if (!firebase.messaging.isSupported() || !('Notification' in window)) {
        this.showToastMessage('This browser is not support web push notification.', 'is-danger')
      } else {
        // Request Permission of Notifications
        Vue.prototype.$messaging
          .requestPermission()
          .then(async () => {
            const response = await this.$subscribeTopic(topic)
            if (response.result === 'success') {
              this.showToastMessage('Enable Report Notification', 'is-success')
            } else {
              this.showToastMessage(response.message, 'is-danger')
            }
          })
          .catch((error) => {
            this.showToastMessage(error, 'is-danger')
          })
      }
    },
    async unsubscribeTopic (topic) {
      try {
        const response = await this.$unsubscribeTopic(topic)
        if (response.result === 'success') {
          this.showToastMessage('Disable Report Notification', 'is-light')
        } else {
          this.showToastMessage(response.message, 'is-danger')
        }
      } catch (error) {
        this.showToastMessage(error, 'is-danger')
      }
    },
    reportNotifyChanged (value) {
      localStorage.setItem('report_notification', value)
      if (value) {
        this.subscribeTopic('new_report')
      } else {
        this.unsubscribeTopic('new_report')
      }
    },
    async getDeviceToken (token) {
      const result = await this.$apollo.query({
        query: getFCMToken,
        variables: {
          user_uid: this.user.uid,
          token
        },
        fetchPolicy: 'network-only'
      })
      return result.data.fcm_tokens[0]
    },
    registerDeviceToken () {
      this.$apollo.mutate({
        mutation: registerFCMToken,
        variables: {
          token: this.token
        },
        update: (cache, { data }) => {
          if (data.insert_fcm_tokens.affected_rows) {
            this.isRegistered = true
            this.showToastMessage('Register this device success', 'is-success')
          }
        }
      })
    },
    unregisterDeviceToken () {
      this.$apollo.mutate({
        mutation: unRegisterFCMToken,
        variables: {
          user_uid: this.user.uid,
          token: this.token
        },
        update: (cache, { data }) => {
          if (data.delete_fcm_tokens.affected_rows) {
            this.isRegistered = false
            this.showToastMessage('Unregister this device success', 'is-success')
          }
        }
      })
    },
    showToastMessage (message, type) {
      this.$buefy.toast.open({
        message: message,
        type,
        position: 'is-bottom'
      })
    },
    showError (message) {
      this.$buefy.dialog.alert({
        title: 'Error',
        type: 'is-danger',
        hasIcon: true,
        icon: 'times-circle',
        iconPack: 'fa',
        message: message
      })
    },
    openLoginDialog () {
      this.authWithFacebook()
    },
    async linkWithFacebook () {
      const provider = new firebase.auth.FacebookAuthProvider()
      await firebase.auth().currentUser.linkWithRedirect(provider)
    },
    async authWithFacebook () {
      const provider = new firebase.auth.FacebookAuthProvider()
      await firebase.auth().signInWithRedirect(provider)
    },
    async linkWithEmail ({ email, password }) {
      try {
        const facebookCredential = firebase.auth.FacebookAuthProvider.credential(this.facebookToken)
        const emailCredential = firebase.auth.EmailAuthProvider.credential(email, password)
        await firebase.auth().currentUser.reauthenticateWithCredential(facebookCredential)
        const { user } = await firebase.auth().currentUser.linkWithCredential(emailCredential)
        this.loginModalActive = false
        if (user) {
          await this.$store.dispatch('signInAction', user)
          this.showToastMessage('Link account successful', 'is-success')
        }
      } catch (error) {
        this.showError(error.message)
      }
    },
    async unlinkAccount (providerId) {
      try {
        await firebase.auth().currentUser.unlink(providerId)
        const user = firebase.auth().currentUser
        if (user) {
          await this.$store.dispatch('signInAction', user)
          this.showToastMessage('Unlink account successful', 'is-success')
        }
      } catch (error) {
        this.showError(error.message)
      }
    }

  }
}
</script>
<style scoped lang="scss">
.card {
  color: #ffffff;
  border: solid 1px rgba(255, 255, 255, 0.07);
  background: rgba(255, 255, 255, 0.05);
}

.card-content {
  padding: 1em;
  strong {
    color: #ffffff;
  }

  h3 {
    font-size: 1.5rem;
  }
}

@media(hover: none) and (pointer: coarse) {
  .section {
    padding: 1rem;
    margin: 5px;
  }
}
</style>
