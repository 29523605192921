import Vue from 'vue'
import Vuex from 'vuex'
import firebase from 'firebase/app'
import { getTokenUserRoles } from '../utils'
import { NotificationProgrammatic as Notification } from 'buefy'
import profileQuery from '../graphql/getMyProfile.gql'

Vue.use(Vuex)

const showNotification = (title, body, type) => {
  Notification.open({
    duration: 3000,
    message: `<h1 class="is-size-4">${title}</h1>
              <p>${body}</p>`,
    position: 'is-bottom-right',
    autoClose: true,
    type,
    hasIcon: true
  })
}

export default new Vuex.Store({
  devtools: true,
  state: {
    user: null,
    role: null,
    error: null,
    socket: {
      isConnected: false,
      message: '',
      reconnectError: false
    }
  },
  getters: {
    user: state => state.user,
    role: state => state.role,
    error: state => state.error,
    isLogIn: state => !!state.user,
    socket: state => state.socket
  },
  mutations: {
    SET_USER (state, payload) {
      state.user = payload.user
      state.role = payload.role
    },
    SET_ERROR (state, payload) {
      state.error = payload
    },
    REMOVE_USER (state, payload) {
      state.user = null
      state.role = null
    },
    SOCKET_ONOPEN (state, event) {
      Vue.prototype.$socket = event.currentTarget
      state.socket.isConnected = true
    },
    SOCKET_ONCLOSE (state, event) {
      state.socket.isConnected = false
      showNotification('Websocket disconnected', `${event.target.url}`, 'is-danger')
    },
    SOCKET_ONERROR (state, event) {
      console.error(state, event)
      showNotification('Websocket error', 'Please refresh the page', 'is-danger')
    },
    // default handler called for all methods
    SOCKET_ONMESSAGE (state, message) {
      state.socket.message = message
    },
    // mutations for reconnect methods
    SOCKET_RECONNECT (state, count) {
      console.error(state, count)
    },
    SOCKET_RECONNECT_ERROR (state) {
      state.socket.reconnectError = true
    }
  },
  actions: {
    async signInAction ({ commit }, user) {
      const userRoles = await getTokenUserRoles()
      if (userRoles && ['moderator', 'admin', 'agency'].some(role => userRoles.includes(role))) {
        const token = await firebase.auth().currentUser.getIdToken()
        localStorage.setItem('firebase-token', token)
        commit('SET_USER', {
          user: {
            providerId: user.providerData,
            uid: user.uid,
            displayName: user.displayName,
            email: user.email,
            emailVerified: user.emailVerified,
            photoURL: user.photoURL
          },
          active_role: 'agency',
          role: userRoles
        })
        commit('SET_ERROR', 'Access denied, No permission to access.')
        return Promise.resolve(token)
      } else {
        localStorage.removeItem('firebase-token')
        commit('SET_ERROR', 'Access denied, No permission to access.')
        return Promise.reject(new Error('Access denied, No permission to access.'))
      }
    },
    async signOutAction ({ commit }) {
      try {
        await firebase.auth().signOut()
        // After logout redirect to login page
        commit('REMOVE_USER')
        commit('SET_ERROR', null)
        localStorage.removeItem('firebase-token')
        return Promise.resolve()
      } catch (error) {
        commit('REMOVE_USER')
        commit('SET_ERROR', error.message)
        return Promise.reject(new Error(error.message))
      }
    },
    async updateUser ({ commit }, { user, apolloProvider }) {
      if (user) {
        const userRoles = await getTokenUserRoles()
        if (userRoles && ['moderator', 'admin', 'agency'].some(role => userRoles.includes(role))) {
          const token = await user.getIdToken()
          localStorage.setItem('firebase-token', token)
          // Get userProfile from hasura
          const client = apolloProvider.defaultClient
          const result = await client.query({
            query: profileQuery,
            variables: {
              uid: user.uid
            },
            fetchPolicy: 'network-only'
          })
          const myProfile = result.data.profile[0]
          const agencyProfile = result.data.agency_manager && result.data.agency_manager.length > 0 ? result.data.agency_manager[0] : null

          commit('SET_USER', {
            user: {
              providerId: user.providerData,
              uid: user.uid,
              displayName: user.displayName,
              email: user.email,
              emailVerified: user.emailVerified,
              photoURL: user.photoURL,
              activeRole: myProfile.role,
              agencyId: agencyProfile ? agencyProfile.agency_id : null
            },
            role: userRoles
          })
        } else {
          commit('SET_ERROR', 'Access denied, No permission to access.')
          commit('SET_USER', { user: null, role: null })
          commit('REMOVE_USER')
        }
      } else {
        commit('SET_USER', { user: null, role: null })
      }
    },
    clearUserSession ({ commit }) {
      commit('REMOVE_USER')
    },
    sendMessage: function (context, message) {
      Vue.prototype.$socket.send(message)
    }
  }
})
