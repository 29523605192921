<template>
  <section class="section">
    <div class="container">
      <div class="media">
        <div class="media-left">
          <h1 class="page-title is-size-3 has-text-white">
            Manage Live (Online {{ onlineUser }} users)
          </h1>
        </div>
        <div class="media-content" />
        <div class="media-right">
          <div class="field">
            <b-switch
              v-model="isLive"
              size="is-medium"
              outlined
            >
              {{ isLive ? 'ออกอากาศ' : 'ไลฟ์สิ้นสุด' }}
            </b-switch>
          </div>
        </div>
      </div>
      <div
        v-if="$apollo.queries.rooms.loading"
        class="hero is-medium"
      >
        <div class="hero-body">
          <span class="is-size-5 has-text-white has-text-centered">
            <div class="spin-loader" />
          </span>
        </div>
      </div>
      <div class="live-feed">
        <div
          v-if="rooms && rooms.length === 0"
          class="has-text-centered"
        >
          <section class="hero is-medium">
            <div class="hero-body">
              <div class="container">
                <img
                  class="icon-image"
                  width="128"
                  alt="live"
                  src="@/assets/live.svg"
                >
                <h3 class="title has-text-white">
                  ไม่มีรายการสด ออกอากาศตอนนี้
                </h3>
              </div>
            </div>
          </section>
        </div>

        <div class="columns is-mobile is-multiline">
          <div
            v-for="live in rooms"
            :key="live.id"
            class="column is-full-mobile is-one-third-tablet is-one-quarter-desktop"
          >
            <live-card :data="live" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from 'vuex'
import GetLiveFeed from '@/graphql/getLiveFeed.gql'

export default {
  name: 'Live',
  components: {
    LiveCard: () => import('@/components/live/LiveCard.vue')
  },
  apollo: {
    rooms: {
      query: GetLiveFeed,
      variables () {
        return {
          ended_at: !this.isLive,
          limit: 100,
          agency_id: this.user.activeRole === 'agency' ? this.user.agencyId : null
        }
      },
      result ({ data, loading, networkStatus }) {
        // if (data.podcasts && data.podcasts.length > 0) {
        //   this.lastOffset += data.podcasts.length
        // }
      }
    }
  },
  data () {
    return {
      isLive: true,
      onlineUser: 0,
      interval: null
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      role: 'role'
    }),
    ...mapGetters([
      'socket'
    ])
  },
  created () {
    // console.log(this.socket.message.data)
    this.$apollo.queries.rooms.startPolling(5000)
  },
  async mounted () {
    if (this.socket.isConnected) {
      this.getOnlineUsers()
      this.startPolling()
    } else {
      await this.sleep(750)
      this.getOnlineUsers()
      this.startPolling()
    }
    this.$socket.onmessage = ({ data }) => {
      const ws = JSON.parse(data)
      if (ws.event === 'online_user') {
        this.onlineUser = ws.data
      }
    }
  },
  beforeDestroy () {
    clearInterval(this.interval)
    this.$apollo.queries.rooms.stopPolling()
  },
  methods: {
    getOnlineUsers () {
      this.$socket.sendObj({ event: 'get_online_users' })
    },
    startPolling () {
      this.interval = setInterval(() => this.getOnlineUsers(), 5000)
    },
    sleep (ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    }
  }
}
</script>
