<template>
  <section class="section">
    <div class="container">
      <div class="media">
        <div class="media-left">
          <h1 class="page-title is-size-3 has-text-white">
            Cashout User
          </h1>
        </div>
        <div class="media-content" />
        <div class="media-right">
          <b-dropdown
            aria-role="list"
            rounded
          >
            <button
              slot="trigger"
              slot-scope="{ active }"
              class="button is-light"
            >
              <span>Download Report</span>
              <b-icon :icon="active ? 'chevron-up' : 'chevron-down'" />
            </button>
            <b-dropdown-item aria-role="listitem">
              Summary Report
            </b-dropdown-item>
            <b-dropdown-item aria-role="listitem">
              Monthly Report
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <b-tabs
        v-model="activeTab"
        size="is-medium"
      >
        <b-tab-item label="บัญชีลงทะเบียน">
          <b-field>
            <b-select
              v-model="registerStatus"
              placeholder="สถานะลงทะเบียน"
              rounded
              size="is-medium"
              @input="onRegisterTypeChanged"
            >
              <option
                value=""
                selected
              >
                ทั้งหมด
              </option>
              <option value="pending">
                รออนุมัติ
              </option>
              <option value="approved">
                อนุมัติ
              </option>
              <option value="rejected">
                ไม่สำเร็จ
              </option>
            </b-select>
          </b-field>

          <b-table
            :data="cashout_users"
            :loading="cashoutLoading"
            :total="totalAccount"
            :per-page="perPage"
            paginated
            sortable
            backend-pagination
            backend-sorting
            @page-change="onCashoutPageChange"
          >
            <b-table-column
              v-slot="props"
              field="user_uid"
              label="User ID"
            >
              <router-link :to="`/users/${props.row.user_uid}`">
                {{ props.row.user_uid }}
              </router-link>
            </b-table-column>

            <b-table-column
              v-slot="props"
              field="name"
              label="Name"
              sortable
            >
              <div class="hover-image">
                <li>
                  {{ props.row.name }}
                  <span class="large">
                    <img
                      :src="props.row.citizen_image_url"
                      class="large-image"
                      alt="citizen-card-image"
                    >
                  </span>
                </li>
              </div>
            </b-table-column>

            <b-table-column
              v-slot="props"
              field="email"
              label="E-mail"
              sortable
            >
              {{ props.row.email }}
            </b-table-column>

            <b-table-column
              v-slot="props"
              field="bank_acc_number"
              label="Account No."
              centered
            >
              {{ formatAccountNo(props.row.bank_acc_number) }}
            </b-table-column>

            <b-table-column
              v-slot="props"
              field="bank_name"
              label="Bank"
            >
              <div class="hover-image">
                <li>
                  <img
                    class="bank-symbol"
                    :src="getBankSymbol(props.row.bank_name)"
                  >
                  <span class="large">
                    <img
                      :src="props.row.bank_acc_image_url"
                      class="large-image"
                      alt="book-bank-image"
                    >
                  </span>
                </li>
              </div>
            </b-table-column>

            <b-table-column
              v-slot="props"
              field="created_at"
              label="Registered"
              sortable
              centered
            >
              {{ props.row.created_at ? new Date(props.row.created_at).toLocaleString() : 'unknown' }}
            </b-table-column>
            <b-table-column
              v-slot="props"
              field="status"
              label="Status"
              centered
            >
              <div :title="props.row.status">
                <b-dropdown
                  hoverable
                  aria-role="list"
                >
                  <b-icon
                    slot="trigger"
                    class="tag"
                    role="button"
                    :type="getStatusIcon(props.row.status).type"
                    :icon="getStatusIcon(props.row.status).icon"
                  />
                  <b-dropdown-item aria-role="listitem">
                    <div class="media">
                      <b-icon
                        class="media-left"
                        icon="check"
                      />
                      <div class="media-content">
                        <h3>อนุมัติ</h3>
                      </div>
                    </div>
                  </b-dropdown-item>
                  <b-dropdown-item aria-role="listitem">
                    <div class="media">
                      <b-icon
                        class="media-left"
                        icon="times"
                      />
                      <div class="media-content">
                        <h3>ไม่อนุมัติ</h3>
                      </div>
                    </div>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </b-table-column>
            <b-table-column
              label="Detail"
            >
              <b-button
                icon-right="info-circle"
              />
            </b-table-column>
          </b-table>
        </b-tab-item>

        <b-tab-item label="คำขอถอนเงิน">
          <b-field>
            <b-select
              v-model="cashoutStatus"
              placeholder="สถานะถอนเงิน"
              rounded
              size="is-medium"
              @input="onCashoutTypeChanged"
            >
              <option
                value=""
                selected
              >
                ทั้งหมด
              </option>
              <option value="pending">
                รอยืนยัน
              </option>
              <option value="waiting">
                รอโอน
              </option>
              <option value="rejected">
                รอแก้ไข
              </option>
              <option value="transferred">
                โอนสำเร็จ
              </option>
            </b-select>
          </b-field>

          <b-table
            :data="cashout"
            :loading="accountLoading"
            :total="totalCashout"
            :per-page="perPage"
            paginated
            sortable
            backend-pagination
            backend-sorting
            @page-change="onCashoutPageChange"
          >
            <b-table-column
              v-slot="props"
              field="user_uid"
              label="User ID"
            >
              <router-link :to="`/users/${props.row.user_uid}`">
                {{ props.row.user_uid }}
              </router-link>
            </b-table-column>
            <b-table-column
              v-slot="props"
              field="amount"
              label="Amount (THB)"
              numeric
              sortable
            >
              {{ formatNumberCommas(props.row.amount) }}
            </b-table-column>

            <b-table-column
              v-slot="props"
              field="created_at"
              label="Requested Date"
              sortable
              centered
            >
              {{ props.row.created_at ? new Date(props.row.created_at).toLocaleString() : 'unknown' }}
            </b-table-column>
            <b-table-column
              v-slot="props"
              field="status"
              label="Status"
              centered
            >
              <div :title="props.row.status">
                <b-dropdown
                  hoverable
                  aria-role="list"
                >
                  <b-icon
                    slot="trigger"
                    class="tag"
                    role="button"
                    :type="getStatusIcon(props.row.status).type"
                    :icon="getStatusIcon(props.row.status).icon"
                  />
                  <b-dropdown-item aria-role="listitem">
                    <div class="media">
                      <b-icon
                        class="media-left"
                        icon="check"
                      />
                      <div class="media-content">
                        <h3>อนุมัติคำขอ</h3>
                      </div>
                    </div>
                  </b-dropdown-item>
                  <b-dropdown-item aria-role="listitem">
                    <div class="media">
                      <b-icon
                        class="media-left"
                        icon="times"
                      />
                      <div class="media-content">
                        <h3>ไม่อนุมัติคำขอ</h3>
                      </div>
                    </div>
                  </b-dropdown-item>
                  <b-dropdown-item aria-role="listitem">
                    <div class="media">
                      <b-icon
                        class="media-left"
                        icon="piggy-bank"
                      />
                      <div class="media-content">
                        <h3>โอนแล้ว</h3>
                      </div>
                    </div>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </b-table-column>
          </b-table>
        </b-tab-item>
      </b-tabs>
    </div>
  </section>
</template>

<script>
import { formatCreatedTime, formatNumberCommas } from '@/utils'
import getUserCashout from '@/graphql/getUserCashout.gql'
import getUserAccount from '@/graphql/getUserAccount.gql'

export default {
  name: 'Cashout',
  apollo: {
    cashout: {
      query: getUserCashout,
      variables () {
        return {
          offset: 0,
          limit: 50,
          status: null
        }
      },
      result ({ data, loading, networkStatus }) {
        if (data && data.cashout.length > 0) {
          this.totalAccount += data.cashout.length
          this.cashoutLoading = loading
        } else {
          this.cashoutLoading = false
        }
      },
      fetchPolicy: 'cache-and-network'
    },
    cashout_users: {
      query: getUserAccount,
      variables () {
        return {
          offset: 0,
          limit: 50,
          status: null
        }
      },
      result ({ data, loading, networkStatus }) {
        if (data && data.cashout_users.length > 0) {
          this.totalCashout += data.cashout_users.length
          this.accountLoading = loading
        } else {
          this.accountLoading = false
        }
      },
      fetchPolicy: 'cache-and-network'
    }
  },
  data () {
    return {
      registerStatus: '',
      cashoutStatus: '',
      data: [],
      totalAccount: 0,
      totalCashout: 0,
      accountLoading: false,
      cashoutLoading: false,
      page: 1,
      perPage: 50,
      activeTab: 0
    }
  },
  methods: {
    formatCreatedTime (isotime) {
      return formatCreatedTime(isotime)
    },
    formatNumberCommas (number) {
      return formatNumberCommas(number)
    },
    formatAccountNo (string) {
      return string.replace(/(\d{3})(\d{1})(\d{5})(\d{1})/, '$1-$2-$3-$4')
    },
    getBankSymbol (name) {
      return require(`@/assets/images/bank/${name}.svg`)
    },
    getStatusIcon (status) {
      switch (status) {
        case 'pending':
          return {
            icon: 'user-clock',
            type: 'is-light'
          }
        case 'approved':
          return {
            icon: 'user-check',
            type: 'is-success'
          }
        case 'waiting':
          return {
            icon: 'user-clock',
            type: 'is-success'
          }
        case 'rejected':
          return {
            icon: 'user-times',
            type: 'is-danger'
          }
        case 'transferred':
          return {
            icon: 'user-check',
            type: 'is-success'
          }
        case 'disabled':
          return {
            icon: 'user-slash',
            type: 'is-dark'
          }
        default:
          return {
            icon: 'user',
            type: 'is-light'
          }
      }
    },
    onRegisterTypeChanged (value) {
      this.$apollo.queries.cashout_users.refetch({
        status: value || null
      })
    },
    onCashoutTypeChanged (value) {
      this.$apollo.queries.cashout.refetch({
        status: value || null
      })
    },
    onCashoutPageChange (page) {
      this.page = page
      // this.loadAsyncData()
    }
  }
}
</script>

<style lang="scss" scoped>
.tag {
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
}
.section {
  @media only screen and (max-width: 767px) {
    padding: 1rem;
  }
}
.report-feed {
  li {
    padding: 1rem;
    @media only screen and (max-width: 767px) {
      padding: 10px;
    }
  }
  .report-card {
    border-radius: 8px;
    background-color:#29394d;
  }
}
.bank-symbol {
  width: 2rem;
}

.hover-image {
  display: flex;

  li {
    list-style-type: none;
    position: relative;
  }

  .large {
    position: absolute;
    left: -9999px;
    width: 500px;
    height: auto;
  }

  li:hover .large {
    left: 0px;
    top: -16px;
    z-index: 999;
  }

  .large-image {
    border-radius: 8px;
  }
}

</style>
