<template>
  <section class="hero is-fullheight">
    <div class="hero-body">
      <div class="container has-text-centered">
        <figure class="image">
          <img
            src="@/assets/no-access.svg"
            class="no-access-image"
          >
        </figure>
        <h1 class="title has-text-white">
          Access Denied
        </h1>
        <h2 class="subtitle has-text-grey-lighter">
          {{ errorMessage }}
        </h2>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'Forbidden',
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.errorMessage = 'You don\'t have the right access this page.'
    })
  },
  data () {
    return {
      errorMessage: null
    }
  }
}
</script>
