import { post } from '../utils/https'

export default {
  install (Vue) {
    // Global methods
    Vue.prototype.$reportUser = (user) => {
      alert(user.tag_name)
    }

    Vue.prototype.$subscribeTopic = async (topic) => {
      try {
        const response = await post(`${process.env.VUE_APP_CLOUD_FUNCTION}/subscribeTopic`, { topic })
        return response
      } catch (error) {
        return new Error(error)
      }
    }

    Vue.prototype.$unsubscribeTopic = async (topic) => {
      try {
        const response = await post(`${process.env.VUE_APP_CLOUD_FUNCTION}/unsubscribeTopic`, { topic })
        return response
      } catch (error) {
        return new Error(error)
      }
    }

    Vue.prototype.$notifyUser = async (uid, data) => {
      const response = await post(`${process.env.VUE_APP_CLOUD_FUNCTION}/notifyUser`, {
        uid: uid,
        title: data.title,
        body: data.body,
        type: data.type,
        data: data.data
      })
      return response
    }

    Vue.prototype.$disableAccount = async (uid) => {
      const response = await post(`${process.env.VUE_APP_CLOUD_FUNCTION}/disableAccount`, {
        uid
      })
      return response
    }

    Vue.prototype.$enableAccount = async (uid) => {
      const response = await post(`${process.env.VUE_APP_CLOUD_FUNCTION}/enableAccount`, {
        uid
      })
      return response
    }

    Vue.mixin({
      methods: {
        capitalizeFirstLetter: str => str.charAt(0).toUpperCase() + str.slice(1)
      }
    })
  }
}
