import Vue from 'vue'
import VueApollo from 'vue-apollo'
import VueNativeSock from 'vue-native-websocket'
// Apollo Client
import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { WebSocketLink } from 'apollo-link-ws'
import { ApolloLink, split } from 'apollo-link'
import { getMainDefinition } from 'apollo-utilities'

import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// Import router and store
import App from './App.vue'
import router from './router'
import store from './store'
// Import plugins
import CloudFunctions from './plugins/functions'
// import { registerDeviceToken } from './utils/device'
// Import libraries
import Buefy, { NotificationProgrammatic as Notification } from 'buefy'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/analytics'
import 'firebase/functions'
import 'firebase/messaging'
// Initialize firebase app
firebase.initializeApp({
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGE_SENDER_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID
})
// Set alias
Vue.prototype.$analytics = firebase.analytics()
// Implement icon-font
library.add(fas)
dom.watch()
// Vue production tip config
Vue.config.productionTip = false
Vue.config.devtools = true

Vue.component('VueFontawesome', FontAwesomeIcon)
Vue.use(Buefy, {
  defaultIconComponent: 'vue-fontawesome',
  defaultIconPack: 'fas',
  materialDesignIcons: false
})
Vue.use(VueApollo)
Vue.use(CloudFunctions)

const getHeaders = () => {
  const obj = {}
  const token = localStorage.getItem('firebase-token')
  if (token) {
    obj.authorization = `Bearer ${token}`
    obj['x-hasura-role'] = 'moderator'
  }
  return obj
}

// Create a http link
const httpLink = new HttpLink({ uri: process.env.VUE_APP_GRAPHQL_HTTP })
// Create a wss link
const wsLink = new WebSocketLink({
  uri: process.env.VUE_APP_GRAPHQL_WSS,
  options: {
    reconnect: true,
    connectionParams: () => {
      return { headers: getHeaders() }
    }
  }
})

const authLink = setContext((_, { headers }) => {
  // return the headers to the context so HTTP link can read them
  return {
    headers: { ...headers, ...getHeaders() }
  }
})

const link = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query)
    return kind === 'OperationDefinition' && operation === 'subscription'
  },
  wsLink,
  authLink.concat(httpLink)
)

// Apollo Client
const apolloClient = new ApolloClient({
  link: ApolloLink.from([link]), // wsLink,
  cache: new InMemoryCache(),
  connectToDevTools: true
})

// Initial apollo client
const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
  prefetch: true,
  errorHandler: (error) => {
    Notification.open({
      duration: 5000,
      message: `<h1 class="is-size-4">Session Expired, please refresh</h1>
                <p>${error.message}</p>`,
      position: 'is-bottom-right',
      type: 'is-danger',
      hasIcon: true
    })
  }
})

// Check messaging support
if (firebase.messaging.isSupported()) {
  Vue.prototype.$messaging = firebase.messaging()
  Vue.prototype.$messaging.usePublicVapidKey(process.env.VUE_APP_FIREBASE_MESSAGE_PUBLIC_KEY)

  navigator.serviceWorker.register(`/${process.env.VUE_APP_FIREBASE_MESSAGE_SW}`)
    .then((registration) => {
      Vue.prototype.$messaging.useServiceWorker(registration)
    }).catch(err => {
      console.error(err)
    })

  // Vue.prototype.$messaging.onTokenRefresh(() => {
  //   Vue.prototype.$messaging.getToken().then((refreshedToken) => {
  //     registerDeviceToken(refreshedToken)
  //   }).catch((err) => {
  //     console.log('Unable to retrieve refreshed token ', err)
  //   })
  // })

  Vue.prototype.$messaging.onMessage((payload) => {
    console.log('Message received. ', payload)
    const src = '/door_bell.aac'
    const audio = new Audio(src)
    let type = 'is-light'

    switch (payload.data.type) {
      case 'new_report':
        type = 'is-warning'
        break
      case 'new_cashout':
        type = 'is-info'
        break
      case 'new_cashout_user':
        type = 'is-light'
        break
      case 'new_podcast':
        type = 'is-light'
        break
      case 'new_live':
        type = 'is-light'
        break
      default:
        type = 'is-danger'
        break
    }

    if (type === 'is-warning') {
      audio.play()
    }

    Notification.open({
      duration: 60000,
      message: `<h1 class="is-size-4">${payload.notification.title}</h1>
                <p>${payload.notification.body}</p>`,
      position: 'is-bottom-right',
      type,
      hasIcon: true
    })
  })
}

let app = ''
// Initialize app only when Firebase Auth object is ready to use
firebase.auth().onAuthStateChanged(async (user) => {
  await store.dispatch('updateUser', { user, apolloProvider })
  if (user) {
    Vue.use(VueNativeSock, `${process.env.VUE_APP_WSS_URL}?platform=website`, {
      store,
      format: 'json',
      protocol: localStorage.getItem('firebase-token') || null,
      reconnection: true
    })
    // Set analytics user propertie
    firebase.analytics().setUserId(user.uid)
    firebase.analytics().setUserProperties({ email: user.email })
  }
  if (!app) {
    app = new Vue({
      el: '#app',
      router,
      store,
      apolloProvider,
      render: h => h(App)
    }).$mount('#app')
  }
})
