import firebase from 'firebase/app'
import { formatDistance, parseISO } from 'date-fns'

export const getTokenUserRoles = async () => {
  try {
    const token = await firebase.auth().currentUser.getIdTokenResult()
    const hasuraClaims = token.claims['https://hasura.io/jwt/claims']
    if (hasuraClaims) {
      return hasuraClaims['x-hasura-allowed-roles']
    } else {
      return null
    }
  } catch (error) {
    console.error(error)
  }
}

export const formatCreatedTime = (date) => {
  if (date) {
    return formatDistance(new Date(), parseISO(date)) + ' ago'
  }
  return ''
}

export const formatNumberCommas = (number) => {
  if (number) {
    const parts = number.toString().split('.')
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    return parts.join('.')
  } else {
    return 0
  }
}

export const stripHtml = (value) => {
  if (!value) { return '' }
  value = value.toString()
  return value.replace(/<[^>]*>/g, '')
}

export const copyToClipboard = (value) => {
  const el = document.createElement('textarea')
  el.value = value
  el.setAttribute('readonly', '')
  el.style.position = 'absolute'
  el.style.left = '-9999px'
  document.body.appendChild(el)

  const selected =
    document.getSelection().rangeCount > 0
      ? document.getSelection().getRangeAt(0)
      : false
  el.select()

  document.execCommand('copy')
  document.body.removeChild(el)
  if (selected) {
    document.getSelection().removeAllRanges()
    document.getSelection().addRange(selected)
  }
}
