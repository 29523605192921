<template>
  <section class="section">
    <div class="container">
      <h1 class="is-size-3 has-text-white">
        Overview
      </h1>
      <!-- <section class="hero is-medium">
        <div class="hero-body">
          <div class="container has-text-centered">
            <figure class="image">
              <img
                src="@/assets/no-access.svg"
                class="no-access-image"
              >
            </figure>
            <h1 class="title has-text-white">
              No permission access
            </h1>
          </div>
        </div>
      </section> -->
      <b-tabs
        v-model="activeTab"
        size="is-medium"
        type="is-toggle-rounded"
        expanded
        class="block"
        @change="onTabChanged"
      >
        <b-tab-item label="Manage Event" />
      </b-tabs>
      <router-view />
    </div>
  </section>
</template>

<script>
import { format, formatDistance, formatRelative, subDays } from 'date-fns'

export default {
  name: 'Overview',
  data () {
    return {
      format,
      formatDistance,
      formatRelative,
      subDays,
      activeTab: 0,
      showBooks: false,
      routes: [
        {
          name: 'event',
          path: '/overview/event'
        },
        {
          name: 'gift',
          path: '/overview/gift'
        }
      ]
    }
  },
  methods: {
    onTabChanged (index) {
      this.$router.push(this.routes[index])
    }
  }
}
</script>

<style lang="scss">
</style>
