<template>
  <section class="section">
    <div class="container">
      <div class="media">
        <div class="media-left">
          <h1 class="page-title is-size-3 has-text-white">
            Podcast Feed
          </h1>
        </div>
        <div class="media-content" />
        <div class="media-right">
          <b-field>
            <b-select
              v-model="podcastStatus"
              placeholder="สถานะ"
              rounded
              size="is-medium"
              @input="onTypeChanged"
            >
              <option
                value="all"
                selected
              >
                All
              </option>
              <option value="hidden">
                Hidden
              </option>
              <option value="published">
                published
              </option>
            </b-select>
          </b-field>
        </div>
      </div>
      <section class="search-section">
        <b-field>
          <b-input
            v-model.trim="searchInput"
            placeholder="Search podcast name"
            size="is-medium"
            :loading="isSearching"
            rounded
            @change="onInputChange"
            @input="onInputChange"
          />
        </b-field>
        <p
          v-if="searchInput"
          class="subtitle has-text-white"
        >
          Search of '{{ searchInput }}' found {{ searchResultCount }} Results
        </p>
      </section>
      <div
        v-if="$apollo.queries.podcasts.loading"
        class="hero is-medium"
      >
        <div class="hero-body">
          <span class="has-text-centered">
            <div class="spin-loader" />
          </span>
        </div>
      </div>
      <template v-if="podcasts && podcasts.length > 0">
        <div class="columns is-mobile is-multiline">
          <div
            v-for="podcast in podcasts"
            :key="podcast.id"
            class="column is-full-mobile is-one-third-tablet is-one-quarter-desktop"
          >
            <div class="card">
              <div class="card-image">
                <figure class="image">
                  <img
                    :src="getThumbnailImage(podcast.cover_image_url)"
                    alt="Podcast cover image"
                    @error="fallbackThumbnailImage"
                  >
                </figure>
              </div>
              <div class="has-text-white">
                <header class="card-header">
                  <h3 class="card-header-title has-text-white is-size-4">
                    {{ podcast.name }}
                  </h3>
                  <router-link
                    class="card-header-icon"
                    aria-label="view"
                    :to="{ name: 'podcastDetail', params: { id: podcast.id }}"
                  >
                    Listen <span class="icon"><i
                      class="fas fa-podcast"
                      aria-hidden="true"
                    /></span>
                  </router-link>
                </header>
                <div class="card-content">
                  <article class="media">
                    <div class="content">
                      <p class="status-tag">
                        {{ podcast.status }}
                      </p>
                      <p class="subtitle is-size-6 has-text-grey-light">
                        {{ getLocalTime(podcast.created_at) }}
                      </p>
                    </div>
                  </article>
                  <div class="media">
                    <div class="media-left">
                      <router-link :to="{ name: 'userDetail', params: { id: podcast.user.uid }}">
                        <figure class="is-64x64">
                          <img
                            v-if="podcast.user.profile_image_url"
                            class="is-rounded profile-image"
                            :src="podcast.user.profile_image_url"
                            width="64"
                            height="64"
                            loading="lazy"
                            alt="Profile image"
                            @error="fallbackProfileImage"
                          >
                          <img
                            v-else
                            src="@/assets/profile/default.svg"
                            width="64"
                            height="64"
                            loading="lazy"
                            alt="Profile image"
                          >
                        </figure>
                      </router-link>
                    </div>
                    <div class="media-content">
                      <p>{{ podcast.user.display_name }}</p>
                      <small class="has-text-grey-light">@{{ podcast.user.tag_name }}</small>
                    </div>
                    <div class="media-right">
                      <b-button
                        outlined
                        type="is-danger"
                        icon-right="flag"
                        @click="reportUser(podcast.user)"
                      />
                    </div>
                  </div>
                </div>
                <footer class="card-footer is-mobile">
                  <div class="card-footer-item has-text-centered has-text-white">
                    <div>
                      <p class="heading">
                        Listeners
                      </p>
                      <p class="is-size-5">
                        {{ podcast.total_listeners }}
                      </p>
                    </div>
                  </div>
                  <div class="card-footer-item has-text-centered">
                    <div>
                      <p class="heading">
                        Likes
                      </p>
                      <p class="is-size-5">
                        {{ podcast.total_likes }}
                      </p>
                    </div>
                  </div>
                  <div class="card-footer-item has-text-centered">
                    <div>
                      <p class="heading">
                        Comments
                      </p>
                      <p class="is-size-5">
                        {{ podcast.total_comments }}
                      </p>
                    </div>
                  </div>
                </footer>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </section>
</template>
<script>
import gql from 'graphql-tag'
import { format } from 'date-fns'
import { stripHtml } from '@/utils'

const GET_TOTAL_PODCASTS = gql`
  query GetTotalPodcasts {
    podcasts_aggregate {
      aggregate {
        count
      }
    }
  }
`

const GET_PODCAST_FEED = gql`
  query GetPodcastsFeed ($offset: Int, $limit: Int, $offsetid: bigint) {
    podcasts(offset: $offset, limit: $limit, order_by: {id: desc}, where: {id: {_gt: $offsetid}}) {
      id
      name
      description
      user {
        uid
        tag_name
        display_name
        profile_image_url
      }
      cover_image_url
      status
      total_comments
      total_gifts
      total_likes
      total_listeners
      created_at
    }
  }
`

const GET_SEARCH_PODCAST = gql`
  query SearchPodcasts($keyword: String){
    podcasts(where: {name: {_ilike: $keyword }}, limit: 50) {
      id
      name
      description
      user {
        uid
        tag_name
        display_name
        profile_image_url
      }
      cover_image_url
      status
      total_comments
      total_gifts
      total_likes
      total_listeners
      created_at
    }
  }
`

export default {
  name: 'Podcast',
  // filters: {
  //   truncate (value, length) {
  //     return value.length > length
  //       ? value.substr(0, length) + '...'
  //       : value
  //   }
  // },
  data () {
    return {
      podcastStatus: 'all',
      offset: 0,
      limit: 50,
      podcasts: [],
      filterStatus: 'all',
      isSearching: false,
      searchInput: '',
      searchResultCount: 0,
      podcastsCount: 0,
      lastOffset: 0,
      isReadMore: false,
      page: 0
    }
  },
  mounted () {
    this.handleScroll()
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll())
  },
  apollo: {
    podcasts: {
      query: GET_PODCAST_FEED,
      variables () {
        return {
          offset: this.offset,
          limit: this.limit
        }
      },
      result ({ data, loading, networkStatus }) {
        if (data.podcasts && data.podcasts.length > 0) {
          this.lastOffset += data.podcasts.length
        }
      }
    },
    podcasts_aggregate: {
      query: GET_TOTAL_PODCASTS,
      result ({ data, loading, networkStatus }) {
        if (data && data.podcasts_aggregate) {
          this.podcastsCount = data.podcasts_aggregate.aggregate.count || 0
        }
      }
    }
  },
  methods: {
    getLocalTime (isotime) {
      return format(new Date(isotime), 'dd MMMM yyyy / HH:mm:ss')
    },
    getThumbnailImage (url) {
      if (url) {
        return url
      }
      return 'https://space.pillowcast.app/image/default/logo.svg'
    },
    fallbackProfileImage (e) {
      e.target.src = 'https://space.pillowcast.app/image/default/default.svg'
    },
    fallbackThumbnailImage (e) {
      e.target.src = 'https://space.pillowcast.app/image/default/logo.svg'
    },
    stripHtml (text) {
      return stripHtml(text)
    },
    onInputChange (keyword) {
      if (keyword.length > 0) {
        this.getSearchResults(keyword)
      } else {
        this.$apollo.queries.podcasts.refetch()
      }
    },
    getSearchResults (keyword) {
      this.$apollo
        .query({
          query: GET_SEARCH_PODCAST,
          variables: {
            keyword: `%${keyword}%` || ''
          }
        })
        .then(data => {
          // Update search result
          this.searchResultCount = data.data.podcasts.length
          this.podcasts = data.data.podcasts
          if (data.loading) {
            this.isSearching = true
          } else {
            this.isSearching = false
          }
        })
        .catch((error) => {
          this.$buefy.toast.open({ message: error, type: 'is-danger', position: 'is-bottom' })
        })
    },
    onTypeChanged (value) {
      console.log(value)
      // switch (value) {
      //   case 'pending':
      //     this.isPodcast = false
      //     this.isLive = true
      //     break
      //   case 'live':
      //     this.isPodcast = true
      //     this.isLive = false
      //     break
      //   default:
      //     this.isPodcast = null
      //     this.isLive = null
      // }
    },
    reportUser (user) {
      this.$buefy.dialog.confirm({
        title: `Report user ${user.tag_name}?`,
        message: 'Are you sure you want to report this account? This action cannot be undone.',
        confirmText: 'Confirm',
        type: 'is-warning',
        hasIcon: true,
        onConfirm: () => {
          this.$reportUser(user)
          this.$buefy.toast.open('User has been reported')
        }
      })
    },
    handleScroll () {
      window.onscroll = () => {
        const bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight === document.documentElement.offsetHeight
        if (bottomOfWindow && this.podcasts.length < this.podcastsCount) {
          console.log(`fetch more podcast start with id ${this.lastOffset}`)// replace it with your code
          this.fetchMore()
        }
      }
    },
    fetchMore () {
      this.$apollo.queries.podcasts.fetchMore({
        variables: {
          offset: this.lastOffset,
          limit: this.limit
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          return {
            podcasts: [...previousResult.podcasts, ...fetchMoreResult.podcasts]
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  background-color: transparent;
  border: 1px solid #29394d;
  box-shadow: none;
  position: relative;
  width: 100%;
  border-radius: 8px;
  padding: 14px;
  color: #ffffff;
  transition: background-color 0.25s ease;
  -webkit-animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
  animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;

  .media {
    border-top: transparent;
  }
  .card-content {
    padding: 1em;
  }

  .card-footer {
    border-top: 1px solid #29394d;
    .card-footer-item:not(:last-child) {
      border-right: 1px solid #29394d;
    }
  }

  &:hover {
    background-color: #233141;
    border: 1px solid rgba(26,179,229,0.15);
  }
}

.search-section {
  margin: 1rem 0 2rem;
}

.status-tag {
  text-transform: capitalize;
  margin-bottom: 0.75rem;
}

.label {
  color: #ffffff;
}

@-webkit-keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 1;
  }
}

@keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 1;
  }
}
</style>
