<template>
  <section class="section">
    <div class="container">
      <h1 class="is-size-3 has-text-white">
        Search User
      </h1>
      <section class="search-section">
        <b-field>
          <b-input
            v-model.trim="searchInput"
            size="is-medium"
            placeholder="Search uid, display name, email, tag name or agency name"
            type="search"
            :loading="isSearching"
            rounded
            @change="onInputChange"
            @input="onInputChange"
          />
        </b-field>
        <p
          v-if="searchInput"
          class="subtitle has-text-white"
        >
          Search of '{{ searchInput }}' found {{ searchResultCount }} Results
        </p>
      </section>
      <div
        v-if="$apollo.queries.users.loading"
        class="hero is-medium"
      >
        <div class="hero-body">
          <span class="has-text-centered">
            <div class="spin-loader" />
          </span>
        </div>
      </div>
      <transition name="fade">
        <template v-if="users && users.length > 0">
          <div class="columns is-mobile is-multiline">
            <div
              v-for="user in users"
              :key="user.uid"
              class="column is-full-mobile is-one-third-tablet is-one-quarter-desktop"
            >
              <user-card :data="user" />
            </div>
          </div>
        </template>
      </transition>
    </div>
  </section>
</template>

<script>
import getUserFeed from '@/graphql/getUserFeed.gql'
import getUserByKeyword from '@/graphql/getUserByKeyword.gql'

export default {
  components: {
    UserCard: () => import('@/components/user/UserCard')
  },
  data () {
    return {
      // user data
      users: [],
      // search box
      isSearching: false,
      searchInput: '',
      searchResultCount: 0,
      // pagination
      total: 0,
      offset: 0,
      limit: 50,
      loading: false,
      page: 1,
      perPage: 20
    }
  },
  methods: {
    fallbackProfileImage (event) {
      event.target.src = 'https://space.pillowcast.app/image/default/default.svg'
    },
    onInputChange (keyword) {
      if (keyword.length > 0) {
        this.getSearchResults(keyword)
      } else {
        this.$apollo.queries.users.refetch()
      }
    },
    messageUser (uid) {
      alert(uid)
    },
    getSearchResults (keyword) {
      this.$apollo
        .query({
          query: getUserByKeyword,
          variables: {
            keyword: `%${keyword}%` || '',
            limit: this.limit
          }
        })
        .then(data => {
          // Update search result
          this.searchResultCount = data.data.users.length
          this.users = data.data.users
          if (data.loading) {
            this.isSearching = true
          } else {
            this.isSearching = false
          }
        })
        .catch((error) => {
          this.$buefy.toast.open({ message: error, type: 'is-danger', position: 'is-bottom' })
        })
    }
  },
  apollo: {
    users: {
      query: getUserFeed,
      variables () {
        return {
          offset: this.offset,
          limit: this.limit
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.profile-card-content {
  flex-direction: column;
  flex: 1;
  min-height: 250px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.search-section {
  margin: 1rem 0 2rem;
}

.profile-content {
  padding: 1rem;
  .display-name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.card {
  margin: 0;
  .card-header,
  .card-content,
  .card-footer {
    justify-content: flex-start;
    align-items: center;
    height: 60px;
  }
  .card-footer-item {
    color: #171717;
    &:hover {
      color: $primary;
    }
  }
}
</style>
