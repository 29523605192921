<template>
  <section class="container">
    <div class="section">
      <template v-if="users[0]">
        <section class="columns user-header">
          <figure class="column is-full-mobile has-text-centered user-profile">
            <figure class="is-128x128">
              <img
                v-if="users[0].profile_image_url"
                class="profile-image image-128"
                :src="users[0].profile_image_url"
                width="128"
                height="128"
                alt="Profile image"
                @error="fallbackProfileImage"
              >
            </figure>
          </figure>
          <div class="column is-full-mobile is-two-thirds-desktop">
            <div class="content user-detail has-text-white">
              <h1 class="title has-text-white">
                {{ users[0].display_name }} (@{{ users[0].tag_name }})
              </h1>
              <p>UID: {{ users[0].uid }}</p>
              <p>E-mail: {{ users[0].email }}</p>
              <p>Agency: {{ users[0].agency ? users[0].agency.name : '-' }}</p>
              <p>Rank: {{ users[0].rank ? users[0].rank.name : '-' }}</p>
              <p>Verify: {{ users[0].verified }}</p>
              <p>Role: {{ users[0].role }}</p>
              <p>Coin Balance: {{ currencyAmount }}</p>
              <p>Registered: {{ getLocalTime(users[0].created_at) }}</p>
              <p>Status: {{ users[0].active ? "Enabled" : "Disabled" }}</p>
              <b-field
                v-if="userRole.includes('admin')"
                type="is-white"
                label="Send gift"
              >
                <b-select
                  placeholder="Select gift"
                  icon="gift"
                  size="is-medium"
                  icon-pack="fa"
                  expanded
                  @input="giftSelected"
                >
                  <optgroup label="General Gifts 🎁">
                    <option
                      v-for="gift in generalGifts"
                      :key="gift.id"
                      :value="gift.id"
                    >
                      {{ gift.name }}
                    </option>
                  </optgroup>
                  <optgroup label="Premium Gifts 💎">
                    <option
                      v-for="gift in premiumGifts"
                      :key="gift.id"
                      :value="gift.id"
                    >
                      {{ gift.name }}
                    </option>
                  </optgroup>
                </b-select>
                <b-input
                  v-model="giftAmount"
                  class="amount-input"
                  expanded
                  type="number"
                  inputmode="numeric"
                  size="is-medium"
                  placeholder="0"
                  min="1"
                />
                <p class="control">
                  <b-button
                    :disabled="!selectGiftId || giftAmount <= 0"
                    type="is-white"
                    label="Send"
                    size="is-medium"
                    :loading="isSending"
                    @click="sendGift"
                  />
                </p>
              </b-field>
            </div>
            <div class="">
              <agency-history :data="agency_user" />
            </div>
          </div>
          <div class="column is-full-mobile">
            <div class="field">
              <b-switch
                v-model="users[0].active"
                size="is-medium"
                rounded
                @input="statusChanged(users[0].active, users[0].uid)"
              >
                {{ users[0].active ? "Enabled" : "Disabled" }}
              </b-switch>
            </div>
          </div>
        </section>
      </template>
      <template v-if="notFound">
        <div class="hero-body">
          <div class="container has-text-centered">
            <figure class="image">
              <img
                src="@/assets/no-result.svg"
                class="no-content-image"
              >
            </figure>
            <h1 class="title has-text-white">User not found</h1>
            <h2 class="subtitle has-text-grey-lighter">
              Maybe this user doesn't exist
            </h2>
          </div>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import { format } from 'date-fns'
import getUserByUid from '@/graphql/getUserByUid.gql'
import getGiftList from '@/graphql/getGiftList.gql'
import sendAdminGift from '@/graphql/sendAdminGift.gql'
import getAgencyUserHistory from '@/graphql/getAgencyUserHistory.gql'

export default {
  components: {
    AgencyHistory: () => import('@/components/user/AgencyHistory')
  },
  data () {
    return {
      uid: this.$route.params.id,
      users: [],
      agency_user: [],
      currencyAmount: 0,
      notFound: false,
      agencyNotFound: false,
      isSending: false,
      giftAmount: 1,
      selectGiftId: null,
      generalGifts: [],
      premiumGifts: []
    }
  },
  computed: {
    ...mapGetters({
      userRole: 'role'
    })
  },
  apollo: {
    users: {
      query: getUserByUid,
      variables () {
        return {
          uid: this.$route.params.id
        }
      },
      result ({ data }) {
        if (data && data.users.length === 0) {
          this.notFound = true
        }
        if (data && data.currency_user.length > 0) {
          this.currencyAmount = data.currency_user[0].amount
        }
      }
    },
    gifts: {
      query: getGiftList,
      result ({ data }) {
        if (data && data.gifts.length > 0) {
          this.generalGifts = data.gifts.filter((gift) => gift.type === 'general')
          this.premiumGifts = data.gifts.filter((gift) => gift.type === 'premium')
        }
      }
    },
    agency_user: {
      query: getAgencyUserHistory,
      variables () {
        return {
          uid: this.$route.params.id
        }
      },
      result ({ data }) {
        if (data && data.users.length === 0) {
          this.agencyNotFound = true
        } else {
          this.agency_user = data.agency_user
        }
      }
    }
  },
  methods: {
    async statusChanged (status, uid) {
      if (status) {
        this.users[0].active = status
        try {
          this.showSucessToast('Processing please wait...', 'is-light')
          const result = await this.$enableAccount(uid)
          if (result) {
            this.showSucessToast('Enable account success', 'is-success')
            this.$apollo.queries.users.refetch()
            this.$analytics.logEvent('enable_account', {
              uid,
              timestamp: new Date().toISOString()
            })
          }
        } catch (error) {
          this.showErrorToast(error)
        }
      } else {
        this.users[0].active = status
        try {
          this.showSucessToast('Processing please wait...', 'is-light')
          await this.$disableAccount(uid)
          this.showSucessToast('Disable account success', 'is-success')
          this.$apollo.queries.users.refetch()
        } catch (error) {
          this.showErrorToast(error)
        }
      }
    },
    getLocalTime (isotime) {
      return format(new Date(isotime), 'dd MMMM yyyy HH:mm:ss')
    },
    sendGift () {
      if (!this.selectGiftId) {
        return alert('Please select gift')
      }
      if (this.giftAmount > 0) {
        const isConfirm = confirm('Are you sure do you want to send this gift?')
        if (isConfirm) {
          this.isSending = true
          this.$apollo.mutate({
            mutation: sendAdminGift,
            variables: {
              gift_id: this.selectGiftId,
              gift_amount: Number(this.giftAmount),
              receiver_uid: this.users[0].uid
            },
            // Clear apollo cache
            update: ({ data }) => {
              console.log(data)
              this.showSucessToast(`Send gift id ${this.selectGiftId} x${this.giftAmount} to ${this.users[0].display_name} successfully`, 'is-success')
              this.isSending = false
            }
          })
        }
      } else {
        alert('Invalid gift amount')
      }
    },
    giftSelected (id) {
      this.selectGiftId = id
    },
    showSucessToast (message, type) {
      this.$buefy.toast.open({
        message,
        type,
        position: 'is-bottom'
      })
    },
    showErrorToast (result) {
      this.$buefy.toast.open({
        message: `Error, ${result.message}`,
        position: 'is-bottom',
        type: 'is-danger'
      })
    },
    fallbackProfileImage (e) {
      e.target.src = 'https://space.pillowcast.app/image/default/default.svg'
    }
  }
}
</script>
<style scoped>
.level-item {
  margin-right: 2rem;
}
.amount-input {
  min-width:30px;
}
.title {
  margin-bottom: 0.75rem;
}
.user-profile {
  margin-right: 1.25rem;
}
.user-detail {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  /* border-bottom: 1px solid rgba(219, 219, 219, 0.5); */
}
.select select option {
  color: #b5b5b5;
}
</style>
