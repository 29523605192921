<template>
  <section class="container">
    <div class="section">
      <template v-if="podcasts[0]">
        <article class="media">
          <div class="media-content">
            <img
              class="image cover-image"
              :src="podcasts[0].cover_image_url"
            >
            <Player :voice-url="podcasts[0].voice_file_url" />
            <section v-if="tag_podcast.length > 1">
              <div
                v-for="data in tag_podcast"
                :key="data.id"
                class="tag"
                rounded
              >
                #{{ data.tag[0].name }}
              </div>
            </section>
            <h1 class="is-size-3 has-text-white">
              {{ podcasts[0].name }}
            </h1>
            <div class="level">
              <p class="level-left is-size-5 has-text-white">
                {{ podcasts[0].total_listeners }} listeners • {{ getLocalTime(podcasts[0].created_at) }}
              </p>
              <b-switch
                class="level-right"
                type="success"
                :v-model="status || getPodcastStatus(podcasts[0].status)"
                :value="getPodcastStatus(podcasts[0].status)"
                @input="changeStatus($event, podcasts[0].id)"
              >
                {{ status ? 'Published' : 'Hidden' }}
              </b-switch>
            </div>
          </div>
        </article>
        <article class="media podcast-detail-panel">
          <div class="media-left">
            <router-link :to="{ name: 'userDetail', params: { id: podcasts[0].user.uid }}">
              <figure class="is-64x64">
                <img
                  v-if="podcasts[0].user.profile_image_url"
                  class="is-rounded profile-image"
                  :src="podcasts[0].user.profile_image_url"
                  width="64"
                  height="64"
                  alt="Profile image"
                >
                <img
                  v-else
                  src="https://space.pillowcast.app/image/default/default.svg"
                  width="64"
                  height="64"
                  loading="lazy"
                  alt="Profile image"
                >
              </figure>
            </router-link>
          </div>
          <div class="media-content">
            <router-link :to="{ name: 'userDetail', params: { id: podcasts[0].user.uid }}">
              <p class="is-size-5 has-text-white is-link">
                {{ podcasts[0].user.display_name }}
              </p>
              <small class="has-text-primary is-link">@{{ podcasts[0].user.tag_name }}</small>
            </router-link>
            <p class="has-text-white">
              {{ stripHtml(podcasts[0].description) }}
            </p>
          </div>
          <div class="media-right">
            <b-button
              type="is-primary"
              icon-pack="fas"
              icon-left="bell"
              rounded
              outlined
              @click="notifyUser(podcasts[0].user, podcasts[0].id)"
            >
              Notify Creator
            </b-button>
          </div>
        </article>
        <article class="total-comment-panel">
          <h2 class="is-size-5 has-text-white">
            {{ totalComments }} Comments
          </h2>
        </article>
        <template v-if="podcast_comments.length > 0">
          <article
            v-for="(comment) in podcast_comments"
            :key="comment.id"
            class="media"
          >
            <div class="media-left">
              <router-link :to="{ name: 'userDetail', params: { id: comment.user.uid }}">
                <figure class="is-64x64">
                  <img
                    v-if="comment.user.profile_image_url"
                    class="is-rounded profile-image"
                    :src="comment.user.profile_image_url"
                    width="64"
                    height="64"
                    alt="Profile image"
                  >
                  <img
                    v-else
                    src="https://space.pillowcast.app/image/default/default.svg"
                    width="64"
                    height="64"
                    loading="lazy"
                    alt="Profile image"
                  >
                </figure>
              </router-link>
            </div>
            <div class="media-content">
              <div class="content">
                <router-link :to="{ name: 'userDetail', params: { id: comment.user.uid }}">
                  <strong class="has-text-white">{{ comment.user.tag_name }}</strong>
                </router-link>
                <p class="comment-content has-text-white-ter">
                  {{ comment.content }}
                </p>
              </div>
              <small class="has-text-grey-light">
                <a
                  href="#"
                  @click.prevent="deleteComment(comment.id)"
                >Delete</a> · {{ getTimeDistance(comment.created_at) }}</small>
            </div>
            <div class="media-right">
              <small class="has-text-grey-light">#{{ comment.id }}</small>
            </div>
          </article>
          <b-pagination
            class="pagination-panel"
            :total="total"
            :current.sync="current"
            :range-before="rangeBefore"
            :range-after="rangeAfter"
            :order="order"
            :per-page="limit"
            @change="onPaginationChanged"
          />
        </template>

        <b-modal
          class="dialog"
          :active.sync="isActionModalActive"
          :can-cancel="false"
          has-modal-card
          trap-focus
        >
          <div class="modal-card notify-dialog">
            <section class="modal-card-body">
              <div class="select-form">
                <b-field
                  label="Sent message to creator"
                  custom-class="is-medium"
                >
                  <b-select
                    v-model="selectedReason"
                    placeholder="Select report reason"
                    expanded
                    rounded
                  >
                    <option
                      v-for="option in reasons"
                      :key="option.id"
                      :value="option.id"
                    >
                      {{ option.body }}
                    </option>
                  </b-select>
                </b-field>
              </div>
            </section>
            <footer class="modal-card-foot">
              <button
                class="button"
                title="Cancel"
                type="button"
                @click="isActionModalActive = false"
              >
                Cancel
              </button>
              <button
                class="button is-primary"
                title="Send"
                @click="sendMessage(podcasts[0].user, selectedReason)"
              >
                Send
              </button>
            </footer>
          </div>
        </b-modal>
      </template>
      <template v-if="notFound">
        <div class="hero-body">
          <div class="container has-text-centered">
            <figure class="image">
              <img
                src="@/assets/no-result.svg"
                class="no-content-image"
              >
            </figure>
            <h1 class="title has-text-white">
              Podcast not found
            </h1>
            <h2 class="subtitle has-text-grey-lighter">
              This podcast doesn't exist
            </h2>
          </div>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import gql from 'graphql-tag'
import { format, formatDistanceToNow } from 'date-fns'
import { stripHtml } from '@/utils'

const GET_PODCAST_BY_ID = gql`
  query GetPodcastById($podcastId: bigint!) {
    podcasts(where: {id: {_eq: $podcastId}}) {
      id
      name
      description
      user {
        uid
        tag_name
        display_name
        profile_image_url
      }
      cover_image_url
      voice_file_url
      total_comments
      total_gifts
      total_likes
      total_listeners
      status
      created_at
    }
  }
`

const GET_PODCAST_COMMENTS = gql`
  query GetPodcastComment($offset: Int, $limit: Int, $podcastId: bigint!) {
    podcast_comments(offset: $offset, limit: $limit, where: {podcast_id: {_eq: $podcastId} }, order_by: {id: asc}) {
      id
      created_at
      content
      type
      user {
        uid
        tag_name
        display_name
        profile_image_url
      }
    },
    podcast_comments_aggregate(where: {podcast_id: {_eq: $podcastId}}) {
      aggregate {
        count
      }
    }
  }
`

const GET_POSTCAST_TAGS = gql`
  query GetPodcastTags ($podcastId: bigint!) {
    tag_podcast(where: {podcast_id: {_eq: $podcastId }}) {
      tag {
        id
        name
      }
    }
  }
`

const UPDATE_PODCAST_STATUS = gql`
  mutation UpdatePodcastStatus ($podcastId: bigint!, $status: String!) {
    update_podcasts(where: {id: {_eq: $podcastId}}, _set: {status: $status}) {
      affected_rows
    }
  }
`

const DELETE_PODCAST_COMMENT = gql`
  mutation DeletePodcastComment ($commentId: bigint!) {
    delete_podcast_comments(where: { id: {_eq: $commentId }}) {
      affected_rows
    }
  }
`

export default {
  name: 'PodcastDetail',
  components: {
    Player: () => import('@/components/Player.vue')
  },
  data () {
    return {
      // podcast meta data
      id: this.$route.params.id,
      podcasts: [],
      podcast_comments: [],
      podcast_comments_aggregate: null,
      status: null,
      totalComments: 0,
      tag_podcast: [],
      notFound: false,
      // reasons dialog
      error: null,
      selectedReason: 1,
      isActionModalActive: false,
      reasons: [{
        id: 1,
        title: 'Podcast ของคุณถูกระงับการเผยแพร่',
        body: 'ชื่อ Podcast ของคุณไม่เหมาะสม กรุณาแก้ไขชื่อ Podcast',
        type: 'content_warning'
      }, {
        id: 2,
        title: 'Podcast ของคุณถูกระงับการเผยแพร่',
        body: 'ภาพมีเนื้อหาไม่เหมาะสม กรุณาแก้ไขภาพพื้นหลังของคุณ',
        type: 'content_warning'
      }, {
        id: 3,
        title: 'Podcast ของคุณถูกระงับการเผยแพร่',
        body: 'Podcast ของคุณมีเนื้อหาผิดเงื่อนไขการใช้งาน โปรดแก้ไขข้อมูล',
        type: 'content_warning'
      }],
      // paginations
      total: 0,
      current: this.$route.query.page ? Number(this.$route.query.page) : 1,
      offset: 0,
      limit: 50,
      rangeBefore: 3,
      rangeAfter: 1,
      order: 'is-centered'
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (newVal, oldVal) {
        if (newVal.query.page) {
          this.offset = Number(this.$route.query.page) > 1 ? Number(this.$route.query.page) * this.limit - this.limit : 0
          this.current = Number(this.$route.query.page)
        }
      }
    }
  },
  methods: {
    getLocalTime (isotime) {
      return format(new Date(isotime), 'dd MMMM yyyy HH:mm:ss')
    },
    getTimeDistance (isotime) {
      return formatDistanceToNow(new Date(isotime), { addSuffix: true, includeSeconds: true })
    },
    getPodcastStatus (status) {
      return status === 'published'
    },
    stripHtml (text) {
      return stripHtml(text)
    },
    changeStatus (event, id) {
      this.$apollo.mutate({
        mutation: UPDATE_PODCAST_STATUS,
        variables: {
          podcastId: id,
          status: event ? 'published' : 'hidden'
        },
        // Clear apollo cache
        update: (cache, { data }) => {
          if (data.update_podcasts.affected_rows) {
            this.status = event
            this.$apollo.queries.podcasts.refetch()
          }
          // cache.writeQuery({ query: UPDATE_PODCAST_STATUS, data })
        }
      })
    },
    notifyUser (user, podcastId) {
      this.isActionModalActive = true
    },
    sendMessage (user, messageId) {
      this.isActionModalActive = false
      this.$notifyUser(user.uid, this.reasons[messageId - 1])
    },
    deleteComment (id) {
      this.$buefy.dialog.confirm({
        title: 'Delete this comment ?',
        message: `Are you sure you want to <b>delete</b> comment id ${id}? This action cannot be undone.`,
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$apollo.mutate({
            mutation: DELETE_PODCAST_COMMENT,
            variables: {
              commentId: id
            },
            // Clear apollo cache
            update: (cache, { data }) => {
              if (data.delete_podcast_comments.affected_rows) {
                this.$buefy.toast.open({ message: 'Comment deleted', position: 'is-bottom' })
                this.$apollo.queries.podcast_comments.refetch()
              }
            }
          })
        }
      })
    },
    onPaginationChanged (page) {
      this.current = page
      this.$router.push({ query: { page: page } })
    }
  },
  apollo: {
    podcasts: {
      query: GET_PODCAST_BY_ID,
      variables () {
        return {
          podcastId: this.$route.params.id
        }
      },
      result ({ data, loading }) {
        if (data && data.podcasts.length === 0) {
          this.notFound = true
        }
        if (data) {
          this.status = data.podcasts[0].status === 'published'
        }
      },
      fetchPolicy: 'cache-and-network'
    },
    podcast_comments: {
      query: GET_PODCAST_COMMENTS,
      variables () {
        return {
          podcastId: this.$route.params.id,
          offset: this.offset,
          limit: this.limit
        }
      },
      result ({ data, loading, networkStatus }) {
        if (data) {
          this.totalComments = data.podcast_comments_aggregate.aggregate.count || 0
        }
      },
      fetchPolicy: 'cache-and-network'
    },
    tag_podcast: {
      query: GET_POSTCAST_TAGS,
      variables () {
        return {
          podcastId: this.$route.params.id
        }
      },
      fetchPolicy: 'cache-and-network'
    }
  }
}
</script>
<style lang="scss" scoped>
.cover-image {
  width: 100%;
  max-height: 600px;
  object-fit: cover;
}

.podcast-detail-panel {
  border-bottom: 1px solid rgba(219, 219, 219, 0.5);
  padding-bottom: 1rem;
}

.total-comment-panel {
  margin: 1.75rem 0;
}

.pagination-panel {
  margin-top: 2rem;
}

.comment-content {
  margin: 0.5rem 0;
}

.notify-dialog {
  width: 500px;
  max-width: 95%;
}

.modal .animation-content .modal-card {
  margin: 0 auto;
}

.select-form {
  margin: 1rem 1rem 0;
}
</style>
