<template>
  <section class="section">
    <div class="container">
      <div class="media">
        <div class="media-left">
          <h1 class="page-title is-size-3 has-text-white">Topup User</h1>
        </div>
        <div class="media-content" />
      </div>
      <b-tabs
        v-model="activeTab"
        size="is-medium"
      >
        <b-tab-item label="ค้นหา">
          <div class="profile-stats is-hidden-desktop">
            <div class="profile-stats-box">
              <div class="profile-data">
                <div class="media profile">
                  <figure class="media-left">
                    <p class="image is-64x64">
                      <img :src="user.photoURL">
                    </p>
                  </figure>
                  <div class="media-content">
                    <div class="content">
                      <p>
                        {{ user.displayName }}
                        <br>
                        @{{ profile ? profile[0].tag_name : "" }}
                        <br>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="profile-stats-box">
              <div class="has-text-centered">
                <div>
                  <div class="heading">จำนวน Coin ที่สามารถโอนได้</div>
                  <div class="title">
                    {{ formatNumberCommas(myCurrency) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="columns">
              <div class="column is-four-fifths">
                <section class="search-section">
                  <b-field>
                    <b-input
                      v-model.trim="searchInput"
                      size="is-medium"
                      placeholder="Search uid, display name, email, tag name or agency name"
                      type="search"
                      :loading="isSearching"
                      rounded
                      @change="onInputChange"
                      @input="onInputChange"
                    />
                  </b-field>
                  <p
                    v-if="searchInput"
                    class="subtitle has-text-white"
                  >
                    Search of '{{ searchInput }}' found
                    {{ searchResultCount }} Results
                  </p>
                </section>
                <div
                  v-if="$apollo.queries.users.loading"
                  class="hero is-medium"
                >
                  <div class="hero-body">
                    <span class="has-text-centered">
                      <div class="spin-loader" />
                    </span>
                  </div>
                </div>
                <transition name="fade">
                  <template v-if="users && users.length > 0">
                    <div class="columns is-mobile is-multiline">
                      <div
                        v-for="user in users"
                        :key="user.uid"
                        class="
                          column
                          is-full-mobile
                          is-one-third-tablet
                          is-one-quarter-desktop
                        "
                      >
                        <user-card
                          :data="user"
                          :packages="packages"
                          :currency="myCurrency"
                          :admin="true"
                          @topupVerify="topupVerify()"
                        />
                      </div>
                    </div>
                  </template>
                </transition>
              </div>
              <div class="column is-hidden-mobile">
                <div class="profile-stats">
                  <div class="profile-stats-box">
                    <div class="profile-data">
                      <div class="media profile">
                        <figure class="media-left">
                          <p class="image is-64x64">
                            <img :src="user.photoURL">
                          </p>
                        </figure>
                        <div class="media-content">
                          <div class="content">
                            <p>
                              {{ user.displayName }}
                              <br>
                              @{{ profile ? profile[0].tag_name : "" }}
                              <br>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="profile-stats-box">
                    <div class="has-text-centered">
                      <div>
                        <div class="heading">จำนวน Coin ที่สามารถโอนได้</div>
                        <div class="title">
                          {{ formatNumberCommas(myCurrency) }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item label="ประวัติการโอน">
          <my-transaction-history />
        </b-tab-item>
      </b-tabs>
    </div>
  </section>
</template>

<script>
import { formatNumberCommas } from '@/utils'
import getUserFeed from '@/graphql/getUserFeed.gql'
import getUserByKeyword from '@/graphql/getUserByKeyword.gql'
import getMyProfile from '@/graphql/getMyProfile.gql'
import getCurrencyBalance from '@/graphql/getCurrencyBalance.gql'
import getCurrencyPackages from '@/graphql/getCurrencyPackages.gql'
import { mapGetters } from 'vuex'

export default {
  name: 'Cashout',
  components: {
    UserCard: () => import('@/components/topup/UserCard'),
    MyTransactionHistory: () => import('@/components/topup/MyTransactionHistory')
  },
  data () {
    return {
      myCurrency: 0,
      activeTab: 0,
      // user data
      users: [],
      // search box
      isSearching: false,
      searchInput: '',
      searchResultCount: 0,
      // pagination
      total: 0,
      offset: 0,
      limit: 50,
      loading: false,
      page: 1,
      perPage: 20,
      packages: []
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      role: 'role'
    })
  },
  apollo: {
    profile: {
      query: getMyProfile
    },
    currency_packages: {
      query: getCurrencyPackages,
      result ({ data }) {
        if (data && data.currency_packages) {
          this.packages = data.currency_packages
        }
      }
    },
    currency_user: {
      query: getCurrencyBalance,
      variables () {
        return {
          id: 1,
          user_uid: this.user.uid
        }
      },
      result ({ data }) {
        if (data && data.currency_user && data.currency_user[0]) {
          this.myCurrency = parseInt(data.currency_user[0].amount)
        } else {
          this.myCurrency = 0
        }
        return this.myCurrency
      }
    },
    users: {
      query: getUserFeed,
      variables () {
        return {
          role: 'agency',
          offset: this.offset,
          limit: this.limit
        }
      }
    }
  },
  methods: {
    async topupVerify () {
      await this.$apollo.queries.currency_user.refetch()
    },
    fallbackProfileImage (event) {
      event.target.src = 'https://space.pillowcast.app/image/default/default.svg'
    },
    onInputChange (keyword) {
      if (keyword.length > 0) {
        this.getSearchResults(keyword)
      } else {
        this.$apollo.queries.users.refetch()
      }
    },
    getSearchResults (keyword) {
      this.$apollo
        .query({
          query: getUserByKeyword,
          variables: {
            keyword: `%${keyword}%` || '',
            limit: this.limit
          }
        })
        .then((data) => {
          // Update search result
          this.searchResultCount = data.data.users.length
          this.users = data.data.users
          if (data.loading) {
            this.isSearching = true
          } else {
            this.isSearching = false
          }
        })
        .catch((error) => {
          this.$buefy.toast.open({
            message: error,
            type: 'is-danger',
            position: 'is-bottom'
          })
        })
    },
    formatNumberCommas (number) {
      return formatNumberCommas(number)
    }
  }
}
</script>

<style lang="scss" scoped>
.profile-card-content {
  flex-direction: column;
  flex: 1;
  min-height: 250px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.search-section {
  margin: 1rem 0 2rem;
}

.profile-stats {
  border: 1px solid #424e5b;
  padding: 0.5rem;
  border-radius: 8px;
  .profile-stats-box {
    padding: 0.5rem 0;
    margin: 0;
    .title {
      color: #e2aa04;
    }
    .heading {
      text-transform: capitalize;
      font-size: 0.875rem;
      color: #fff;
    }
  }
  .profile-data {
    color: #b5b5b5;
    border-bottom: 1px solid #424e5b;
    padding: 0 0 1rem 0;
    img {
      border-radius: 50%;
    }
  }
}

.profile-content {
  padding: 1rem;
  border-top: transparent;
  .display-name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
.card {
  margin: 0;
  .card-header,
  .card-content,
  .card-footer {
    justify-content: flex-start;
    align-items: center;
    height: 60px;
  }
  .card-footer-item {
    color: #171717;
    &:hover {
      color: $primary;
    }
  }
}
.tag {
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
}
.section {
  @media only screen and (max-width: 767px) {
    padding: 1rem;
  }
}
.report-feed {
  li {
    padding: 1rem;
    @media only screen and (max-width: 767px) {
      padding: 10px;
    }
  }
  .report-card {
    border-radius: 8px;
    background-color: #29394d;
  }
}
.bank-symbol {
  width: 2rem;
}

.hover-image {
  display: flex;

  li {
    list-style-type: none;
    position: relative;
  }

  .large {
    position: absolute;
    left: -9999px;
    width: 500px;
    height: auto;
  }

  li:hover .large {
    left: 0px;
    top: -16px;
    z-index: 999;
  }

  .large-image {
    border-radius: 8px;
  }
}
</style>
