<template>
  <div id="app">
    <template v-if="userLogedIn">
      <Navbar />
      <router-view />
    </template>
    <template v-else>
      <Login />
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'App',
  components: {
    Navbar: () => import('@/components/Navbar'),
    Login: () => import('@/views/Login')
  },
  computed: {
    // Watch login session state
    ...mapGetters({
      userLogedIn: 'isLogIn'
    })
  },
  watch: {
    '$route' (to, from) {
      document.title = to.meta.title || 'Pillow Cast Console'
    }
  }
}
</script>

<style lang="scss">
// Import Bulma's core
@charset "utf-8";
@import "~bulma/sass/utilities/_all";

// Set your colors
$primary: #ffcd00;
$link: #ffcd00;
$facebook: #4099FF;
$facebook-invert: findColorInvert($facebook);
$success: #4cd964;

// Set background colors
$background-color: #132233;
$dark-background-color: #060c13;

// Set font styles
$font-family: 'Kanit', Helvetica, Arial, sans-serif;
$font-color: #ffffff;

$card-header-shadow: none;
// Setup $colors to use as bulma classes (e.g. 'is-facebook')
$colors: (
  "white": ($white, $black),
  "black": ($black, $white),
  "light": ($light, $light-invert),
  "dark": ($dark, $dark-invert),
  "primary": ($primary, $white),
  "link": ($primary, $white),
  "info": ($info, $info-invert),
  "success": ($success, $success-invert),
  "warning": ($warning, $warning-invert),
  "danger": ($danger, $danger-invert),
  "facebook": ($facebook, $facebook-invert),
  "dark-background": ($dark-background-color, $white)
);

// Links
$link: $primary;
$link-active: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

// Tabs
$tabs-link-color: $font-color;
$tabs-border-bottom-color: #3a4550;
$tabs-link-padding: 1rem 2rem;
$tabs-link-hover-color: $font-color;
$tabs-link-hover-border-bottom-color: none;

// Navbar
$navbar-burger-color: #ffffff;
$navbar-item-color: #ffffff;
$navbar-background-color:#132233;
$navbar-item-active-color: #ffcd00;
$navbar-item-hover-color: #ffcd00;
$navbar-tab-active-color: #ffcd00;
$navbar-dropdown-item-hover-color: #ffcd00;
$navbar-dropdown-item-active-color: #ffcd00;
$navbar-item-hover-background-color: transparent;
$navbar-dropdown-background-color: #132233;
$navbar-dropdown-border-top: none;

// Paginations
$pagination-color: $font-color;
$pagination-current-color: $black;
$pagination-hover-color: $primary;
$pagination-hover-border-color: $primary;
$pagination-current-border-color: $white;
$pagination-current-background-color: $white;
$pagination-disabled-background-color: transparent;

// Dropdown
$dropdown-item-color: #ffffff;
$dropdown-background-color: #132233;
$dropdown-item-active-color: #000000;
$dropdown-content-background-color: rgb(28, 39, 51);
$dropdown-divider-background-color: #29394d;

// Input
$label-color: #ffffff;
$label-weight: 500;
$input-color: rgb(210, 210, 210);
$input-background-color: #29394d;
$input-border-color: solid 1px rgba(210, 210, 210, 0.2);
$input-hover-border-color: none;

// Table
$table-color: #ffffff;
$table-cell-heading-color: #ffffff;
$table-head-cell-border-width: 0 0 1px;
$table-head-cell-color: #ffffff;
$table-background-color: transparent;
$table-cell-border: 1px solid #3a4550;
$table-cell-padding: 1em;

// Modal
$modal-card-title-color: #ffffff;
$modal-card-body-color: #ffffff;
$modal-card-head-background-color: #132233;
$modal-card-body-background-color: #132233;
$modal-card-head-border-bottom: 1px solid #29394d;
$modal-card-foot-border-top: 1px solid #29394d;

// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";

// Custom css to override
html {
  background-color: $background-color;
}

body {
  font-family: $font-family;
  font-weight: normal;
  background-color: $background-color;
  min-height: 100vh;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon svg {
  width: 2.75rem;
  height: 2.75rem;
}

.modal-card-body {
  color: #ffffff;
}

.button.is-yellow {
  background-image: linear-gradient(295deg, #e2aa04, #ffe13b);
  border-color: transparent;
  color: $font-color;
  font-weight: 500;
  padding: 0 3rem;
  height: 3em;
  border-radius: 28px;
}

.tag {
  &:not(body) {
    font-size: 1rem;
    background-color: transparent;
    color: $primary;
    padding-left: 0;
  }
}

.control-label {
  color: #ffffff;
}

.navbar-brand {
  margin-right: 32px;
}

.badge-image {
  width: 16px;
  height: 16px;
}

.profile-image {
  width: 64px;
  height: 64px;
  border-radius: 50%;

  &.image-128 {
    width: 128px;
    height: 128px;
  }
  &:hover {
    opacity: 0.75;
  }
}

.is-link {
  &:hover {
    opacity: 0.75;
  }
}

.page-title {
  margin-bottom: 2rem;
}

.live-feed {
  .icon-image {
    margin-bottom: 2rem;
  }
}

.card {
  margin: 2em 0;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

  &.is-horizontal {
    flex-direction: row;
    display: flex;
    flex-basis: 50ex;
    flex-grow: 0;
    flex-shrink: 1;

    .card-image {
      flex: 1;
      width: 100%;
      height: 100%;

      .image img {
        object-fit: cover;
        object-position: 50% 50%;
        height: 100%;
        width: 100%;
        min-width: 500px;
        @media only screen and (max-width: 600px) {
          min-width: auto;
          height: 250px;
        }
      }

      &.is-right {
        flex-direction: row-reverse
      }
    }

    .card-stacked {
      flex-direction: column;
      flex: 1 1 auto;
      display: flex;
      position: relative;
    }

    .card-header {
      flex: 1;
      padding: 0.5rem;
    }

    .card-content {
      flex: 2;
    }

    .media:not(:last-child) {
      margin-bottom: 0.75rem;
    }

    .level.is-mobile .level-item:not(:last-child) {
      margin-right: 0rem;
    }
  }
}

.no-content-image {
  max-width: 450px;
  height: auto;
  margin: 2em auto;
}

.no-access-image {
  max-width: 250px;
  height: auto;
  margin: 2em auto;
}

.spin-loader,
.spin-loader:before,
.spin-loader:after {
  border-radius: 50%;
  width: 1em;
  height: 1em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.spin-loader {
  color: #ffffff;
  font-size: 14px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.spin-loader:before,
.spin-loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.spin-loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.spin-loader:after {
  left: 3.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

</style>
