<template>
  <div>
    Annoucement Page
  </div>
</template>

<script>
import gql from 'graphql-tag'

const GET_GIFTS_LIST = gql`
  query GetGiftList {
    gifts(order_by: {order: asc}) {
      id
      name
      image_url
      price
    }
  }
`

export default {
  name: 'Annoucement',
  data () {
    return {
      gifts: [],
      error: null
    }
  },
  apollo: {
    gifts: {
      query: GET_GIFTS_LIST,
      result ({ data, loading, networkStatus }) {
        console.log(networkStatus)
        console.log(data)
      },
      error (error) {
        this.error = JSON.stringify(error.message)
      }
    }
  }
}
</script>
