<template>
  <section class="section">
    <div class="container">
      <div class="media">
        <div class="media-left">
          <h1 class="page-title is-size-3 has-text-white">
            User Report
          </h1>
        </div>
        <div class="media-content" />
        <div class="media-right">
          <b-field>
            <b-select
              v-model="reportType"
              placeholder="ประเภทรีพอตท์"
              rounded
              size="is-medium"
              @input="onTypeChanged"
            >
              <option
                value="all"
                selected
              >
                All types
              </option>
              <option value="podcast">
                Cast
              </option>
              <option value="live">
                Live
              </option>
              <option value="post">
                Post
              </option>
              <option value="comment">
                Comment
              </option>
              <option value="profile">
                User
              </option>
            </b-select>
          </b-field>
        </div>
      </div>
      <div v-if="report_users && report_users.length === 0">
        <section class="hero is-medium">
          <div class="hero-body">
            <div class="has-text-centered">
              <img
                class="icon-image"
                width="512"
                alt="live"
                src="@/assets/no-content.svg"
              >
              <h3 class="title has-text-white">
                ไม่มี Report ผู้ใช้งาน
              </h3>
            </div>
          </div>
        </section>
      </div>
      <div
        v-if="report_users && report_users.length > 0"
        class="columns is-multiline report-feed"
      >
        <ul
          v-for="report in report_users"
          :key="report.id"
          class="column is-full"
        >
          <li class="report-card">
            <div class="media has-text-white">
              <div class="media-content">
                <p>
                  ผู้ถูกรายงาน: <router-link :to="{ name: 'userDetail', params: { id: report.user_uid }}">
                    {{ report.user.display_name }} (@{{ report.user.tag_name }})
                  </router-link>
                </p>
                <p>เหตุผล: {{ report.reason.name }}</p>
                <p>
                  ประเภท:  <router-link
                    :to="{ name: getReportType(report).page, params: { id: getReportType(report) ? getReportContent(report).id : null }}"
                  >
                    {{ getReportType(report).name }}
                  </router-link> / ความคิดเห็น: {{ getReportComment(report.comment_id) ? 'ใช่' : 'ไม่ใช่' }}
                </p>
                <p>
                  รายงานโดย: <router-link :to="{ name: 'userDetail', params: { id: report.reported_by }}">
                    {{ report.reported_by }}
                  </router-link>
                </p>
                <p class="">
                  {{ report.id }} | รายงานเมื่อ {{ formatCreatedTime(report.created_at) }}
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import { formatCreatedTime, formatNumberCommas } from '@/utils'
import getUserReport from '@/graphql/getUserReport.gql'

export default {
  name: 'UserReport',
  apollo: {
    report_users: {
      query: getUserReport,
      variables () {
        return {
          isPost: this.isPost,
          isComment: this.isComment,
          isPodcast: this.isPodcast,
          isLive: this.isLive
        }
      },
      fetchPolicy: 'cache-and-network'
    }
  },
  data () {
    return {
      reportType: 'all',
      isPost: null,
      isComment: null,
      isPodcast: null,
      isLive: null,
      report_users: []
    }
  },
  methods: {
    formatCreatedTime (isotime) {
      return formatCreatedTime(isotime)
    },
    formatNumberCommas (number) {
      return formatNumberCommas(number)
    },
    getReportContent (data) {
      if (data.room_id) {
        return {
          type: 'live',
          id: data.room_id || 0
        }
      } else if (data.podcast_id) {
        return {
          type: 'podcast',
          id: data.podcast_id || 0
        }
      } else if (data.comment_id) {
        return {
          type: 'podcast',
          id: data.comment_id || 0
        }
      } else if (data.post_id) {
        return {
          type: 'post',
          id: data.post_id || 0
        }
      } else {
        return {
          type: 'user',
          id: data.user_uid
        }
      }
    },
    getReportType (data) {
      if (data.room_id) return { name: 'Live', page: 'liveDetail' }
      else if (data.podcast_id) return { name: 'Cast', page: 'podcastDetail' }
      else return { name: 'Profile', page: 'userDetail' }
    },
    getReportComment (commentId) {
      return commentId !== null
    },
    onTypeChanged (value) {
      switch (value) {
        case 'comment':
          this.isComment = false
          this.isPost = true
          this.isPodcast = true
          this.isLive = true
          break
        case 'post':
          this.isComment = true
          this.isPost = false
          this.isPodcast = true
          this.isLive = true
          break
        case 'podcast':
          this.isPost = true
          this.isComment = true
          this.isPodcast = false
          this.isLive = true
          break
        case 'live':
          this.isPost = true
          this.isComment = true
          this.isPodcast = true
          this.isLive = false
          break
        default:
          this.isPost = null
          this.isComment = null
          this.isPodcast = null
          this.isLive = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.section {
  @media only screen and (max-width: 767px) {
    padding: 1rem;
  }
}
.report-feed {
  li {
    padding: 1rem;
    @media only screen and (max-width: 767px) {
      padding: 10px;
    }
  }
  .report-card {
    border-radius: 8px;
    border: 1px solid #29394d;
    background-color: #233141;
    -webkit-animation: slide-in-fwd-center 0.4s ease-in-out both;
    animation: slide-in-fwd-center 0.4s ease-in-out both;
    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
}

@-webkit-keyframes slide-in-fwd-center {
  0% {
    -webkit-transform: translateZ(-1400px);
            transform: translateZ(-1400px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes slide-in-fwd-center {
  0% {
    -webkit-transform: translateZ(-1400px);
            transform: translateZ(-1400px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}

</style>
