<template>
  <section class="hero is-fullheight">
    <div class="hero-body">
      <div class="container has-text-white has-text-centered">
        <div class="columns is-centered">
          <div class="column is-half">
            <div
              v-if="!userLogedIn"
              class="card"
            >
              <div class="card-image">
                <figure class="image is-128x128">
                  <img
                    src="https://space.pillowcast.app/image/default/logo.svg"
                    alt="Placeholder image"
                  >
                </figure>
              </div>
              <div class="card-content">
                <h1>PillowCast Console</h1>
                <p>Log in with authorized account</p>
              </div>
              <footer class="card-footer">
                <div class="card-footer-item">
                  <b-button
                    class="btn email-color"
                    size="is-medium"
                    :loading="isEmailProcessing"
                    rounded
                    expanded
                    @click.native.prevent="openLoginDialog()"
                  >
                    Sign in with E-mail
                  </b-button>
                  <b-button
                    class="btn apple-color"
                    size="is-medium"
                    :loading="isAppleProcessing"
                    rounded
                    expanded
                    @click.native.prevent="loginWithApple()"
                  >
                    Sign in with Apple
                  </b-button>
                  <b-button
                    class="btn fb-color"
                    size="is-medium"
                    :loading="isFacebookProcessing"
                    rounded
                    expanded
                    @click.native.prevent="loginWithFacebook()"
                  >
                    Sign in with Facebook
                  </b-button>
                  <b-button
                    class="btn gg-color"
                    size="is-medium"
                    :loading="isGoogleProcessing"
                    rounded
                    expanded
                    @click.native.prevent="loginWithGoogle()"
                  >
                    Sign in with Google
                  </b-button>
                </div>
              </footer>
            </div>
            <div
              v-else
              class="card"
            >
              <div class="card-image">
                <figure class="image is-128x128">
                  <img
                    src="https://space.pillowcast.app/image/default/logo.svg"
                    alt="Placeholder image"
                  >
                </figure>
              </div>
              <div class="card-content">
                <h1>Welcome to console</h1>
                <p>{{ userData.displayName || 'PillowCast\'s moderator' }}</p>
              </div>
              <footer class="card-footer">
                <p class="card-footer-item">
                  <b-button
                    class="btn"
                    size="is-medium"
                    rounded
                    @click.native.prevent="logout()"
                  >
                    Sign out
                  </b-button>
                </p>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      :active.sync="loginModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      custom-class="login-modal"
      aria-role="dialog"
      aria-modal
    >
      <login-modal
        @login-click="loginWithEmail"
      />
    </b-modal>
  </section>
</template>
<script>
import { mapGetters } from 'vuex'
import firebase from 'firebase/app'

export default {
  name: 'Login',
  components: {
    LoginModal: () => import('@/components/login/LoginModal.vue')
  },
  data () {
    return {
      isEmailProcessing: false,
      isAppleProcessing: false,
      isFacebookProcessing: false,
      isGoogleProcessing: false,
      loginModalActive: false
    }
  },
  computed: {
    // Watch login session state
    ...mapGetters({
      userLogedIn: 'isLogIn',
      userData: 'user'
    })
  },
  async created () {
    try {
      const { user } = await firebase.auth().getRedirectResult()
      const pendingCred = sessionStorage.getItem('pending-cred')
      if (user) {
        if (pendingCred) {
          user.linkWithCredential(pendingCred)
        }
        await this.$store.dispatch('signInAction', user)
        // this.$router.push('/overview')
      }
    } catch (error) {
      await this.$store.dispatch('signOutAction')
      this.showError(error.message)
    }
  },
  methods: {
    openLoginDialog () {
      this.loginModalActive = true
    },
    async loginWithEmail ({ email, password }) {
      try {
        this.isEmailProcessing = true
        const { user } = await firebase.auth().signInWithEmailAndPassword(email, password)
        if (user) {
          await this.$store.dispatch('signInAction', user)
          this.loginModalActive = false
          // this.$router.push('/overview')
        }
        this.isEmailProcessing = false
      } catch (error) {
        this.loginModalActive = false
        await this.$store.dispatch('signOutAction')
        this.showError(error.message)
      }
    },
    async loginWithGoogle () {
      try {
        this.isGoogleProcessing = true
        await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        const provider = new firebase.auth.GoogleAuthProvider()
        await firebase.auth().signInWithRedirect(provider)
      } catch (error) {
        // An error happened.
        if (error.code === 'auth/account-exists-with-different-credential') {
          // User's email already exists. The pending Google credential.
          sessionStorage.setItem('pending-cred', error.credential)
          // The provider account's email address.
          const email = error.email
          // Get sign-in methods for this email.
          firebase.auth().fetchSignInMethodsForEmail(email).then((methods) => {
            // If the user has several sign-in methods,
            // the first method in the list will be the "recommended" method to use.
            if (methods[0] === 'password') {
              // Asks the user their password.
              this.loginModalActive = true
            } else {
              alert(methods[0])
            }
          })
        } else {
          this.showError(error.message)
        }
      }
    },
    async loginWithFacebook () {
      try {
        this.isFacebookProcessing = true
        await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        const provider = new firebase.auth.FacebookAuthProvider()
        await firebase.auth().signInWithRedirect(provider)
      } catch (error) {
        // An error happened.
        if (error.code === 'auth/account-exists-with-different-credential') {
          // User's email already exists. The pending Facebook credential.
          sessionStorage.setItem('pending-cred', error.credential)
          // The provider account's email address.
          const email = error.email
          // Get sign-in methods for this email.
          firebase.auth().fetchSignInMethodsForEmail(email).then((methods) => {
            // If the user has several sign-in methods,
            // the first method in the list will be the "recommended" method to use.
            if (methods[0] === 'password') {
              // Asks the user their password.
              this.loginModalActive = true
            } else {
              alert(methods[0])
            }
          })
        } else {
          this.showError(error.message)
        }
      }
    },
    async loginWithApple () {
      try {
        this.isAppleProcessing = true
        await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        const provider = new firebase.auth.OAuthProvider('apple.com')
        await firebase.auth().signInWithRedirect(provider)
      } catch (error) {
        this.showError(error.message)
      }
    },
    async logout () {
      await this.$store.dispatch('signOutAction')
      if (this.$route.path !== '/login') {
        this.$router.push('/login')
      }
    },
    showError (message) {
      this.$buefy.dialog.alert({
        title: 'Error',
        type: 'is-danger',
        hasIcon: true,
        icon: 'times-circle',
        iconPack: 'fa',
        message: message
      })
      this.isEmailProcessing = false
      this.isAppleProcessing = false
      this.isGoogleProcessing = false
      this.isFacebookProcessing = false
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  padding-top: 1rem;
  background-color: transparent;
  border: 1px solid #3a4550;
  box-shadow: none;
}

.card-image {
  padding-top: 1rem;
}

.card-content {
  color: #ffffff;
  padding: 1rem;
  h1 {
    font-size: 2rem;
    font-weight: 500;
  }
  p {
    font-size: 1rem;
  }
}

.card-footer {
  border-top: none;

  .card-footer-item {
    flex-wrap: wrap;
  }
}

.image {
  margin: 0 auto;
}

.btn {
  width: 100%;
  max-width: 350px;
  margin-bottom: 1rem;
  border: none;
  background-color: #efeff4;
  color: #000;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2);
  &:hover {
    background-color: #121212;
    color: #ffffff;
  }
}

.fb-color {
  color: #ffffff;
  background-color: #3b5998;
  &:hover {
    background-image: none;
  }
}

.gg-color {
  color: #171717;
  background-color: #fff;
  &:hover {
    background-image: none;
  }
}

.email-color {
  color: #ffffff;
  background-color: #e2aa04;
  &:hover {
    background-image: none;
  }
}

.apple-color {
  color: #ffffff;
  background-color: #000000;
  &:hover {
    background-image: none;
  }
}
</style>
