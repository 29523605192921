<template>
  <section class="container">
    <router-link
      class="return-link"
      :to="{ name: 'live' }"
    >
      Back to Feed
    </router-link>
    <div
      v-if="lives"
      class="live-section"
    >
      <h1 class="title is-size-2 has-text-white">
        {{ lives[0].title }}
      </h1>
      <div class="columns">
        <div class="column is-one-third live-background-container">
          <img
            :src="getThumbnailImage(lives[0].cover_image_url)"
            width="500"
            height="500"
            loading="lazy"
          >
        </div>
        <div class="column live-detail-container">
          <section class="user-profile-panel">
            <div class="media">
              <div class="media-left">
                <router-link :to="`/users/${lives[0].user_uid}`">
                  <figure class="is-48x48">
                    <img
                      width="64"
                      height="64"
                      class="is-rounded profile-image"
                      :src="lives[0].user.profile_image_url"
                      alt="profile image"
                      @error="fallbackProfileImage"
                    >
                  </figure>
                </router-link>
              </div>
              <div class="media-content">
                <h3 class="has-text-white is-size-5">
                  {{ lives[0].user.display_name }}
                </h3>
                <p class="has-text-grey-lighter is-size-7">
                  @{{ lives[0].user.tag_name }}
                </p>
              </div>
            </div>
          </section>
          <hr class="dropdown-divider">
          <section class="live-detail has-text-white">
            <p>Description: {{ lives[0].description }}</p>
            <p>{{ formatCreatedTime(lives[0].created_at) }}</p>
            <p>Total Likes: {{ formatNumberCommas(lives[0].total_likes) }}</p>
            <p>Total Gifts: {{ formatNumberCommas(lives[0].total_gifts) }}</p>
          </section>
          <hr class="dropdown-divider">
          <section class="call-member-section has-text-white">
            <span v-if="!lives[0].allowed_speaker">
              <p>Not allow speaker</p>
            </span>
            <span v-if="lives[0].allowed_speaker && speakerList.length === 0">
              <p>Empty speaker member</p>
            </span>
            <span v-if="lives[0].allowed_speaker && speakerList.length > 0">
              <ul
                v-for="member in speakerList"
                :key="member.uid"
                class="is-flex"
              >
                <li class="call-member">
                  <router-link :to="`/users/${member.uid}`">
                    <figure class="is-64x64">
                      <img
                        width="64"
                        height="64"
                        class="is-rounded profile-image"
                        :src="member.profile_image_url"
                        alt="profile image"
                        @error="fallbackProfileImage"
                      >
                    </figure>
                    <p>{{ member.display_name }}</p>
                  </router-link>
                </li>
              </ul>
            </span>
          </section>
          <hr class="dropdown-divider">
          <section class="call-member-section has-text-white">
            <span v-if="requestList.length > 0">
              <ul
                v-for="member in requestList"
                :key="member.uid"
                class="is-flex"
              >
                <li class="call-member">
                  <router-link :to="`/users/${member.uid}`">
                    <figure class="is-64x64">
                      <img
                        width="64"
                        height="64"
                        class="is-rounded profile-image"
                        :src="member.profile_image_url"
                        alt="profile image"
                        @error="fallbackProfileImage"
                      >
                    </figure>
                    <p>{{ member.display_name }}</p>
                  </router-link>
                </li>
              </ul>
            </span>
            <span v-else><p>Empty request call member</p></span>
          </section>
          <hr class="dropdown-divider">
          <section class="action-panel columns">
            <div class="column">
              <b-button
                expanded
                outlined
                type="is-light"
                size="is-medium"
                icon-right="headphones-alt"
                @click="getRoomAudience"
              >
                {{ members }} Room Listener
              </b-button>
            </div>
            <div class="column">
              <b-button
                expanded
                outlined
                type="is-warning"
                size="is-medium"
                icon-right="stop-circle"
                @click="confirmTerminateRoom(lives[0].id)"
              >
                Terminate Room
              </b-button>
            </div>
            <div
              v-if="users"
              class="column"
            >
              <b-button
                expanded
                outlined
                :type="users.active ? 'is-danger' : 'is-success'"
                size="is-medium"
                :icon-right="users.active ? 'ban' : 'minus-circle'"
                :loading="processing"
                @click="users.active ? confirmDisableAccount(lives[0].user_uid) : enableAccount(lives[0].user_uid)"
              >
                {{ users.active ? 'Disable Account' : 'Enable Account' }}
              </b-button>
            </div>
          </section>
        </div>
      </div>
      <hr class="dropdown-divider">
      <div
        class="chat-panel"
      >
        <section
          id="chatPanel"
          @scroll="handleScroll"
        >
          <ul
            v-for="message in roomMessage"
            :key="message.id"
            class="has-text-white is-flex message-list"
          >
            <div class="media message-item">
              <div class="media-left">
                <div class="is-flex profile-link">
                  <router-link
                    :to="`/users/${message.user.uid}`"
                  >
                    <figure class="image is-48x48">
                      <img
                        width="48"
                        height="48"
                        class="profile-image"
                        :src="message.type === 'admin' ? 'https://space.pillowcast.app/image/default/logo.svg' : message.user.profile_image_url"
                        alt="profile image"
                        @error="fallbackProfileImage"
                      >
                    </figure>
                  </router-link>
                </div>
              </div>
              <div class="media-content">
                <p class="display-name">
                  {{ message.type === 'admin' ? 'PillowCast\'s Admin': message.user.display_name }}
                </p>
                <p class="content">
                  {{ message.content }}
                </p>
                <p
                  v-if="message.type === 'gift' || message.type === 'gift_text'"
                  class="gift"
                >
                  <img
                    :src="message.data.image_url"
                    loading="eager"
                    class="gift-image"
                  >
                  <span class="gift-amount">x{{ message.data.amount }}</span>
                  <span class="gift-content"> {{ message.content }}</span>
                </p>
                <p
                  v-if="message.type === 'request_call' || message.type === 'follow'"
                  class="content"
                  :class="message.type"
                >
                  {{ message.content }}
                </p>
              </div>
              <div class="media-right">
                <small class="has-text-grey create-time">
                  {{ formatCreatedTime(message.created_at) }}
                </small>
              </div>
            </div>
          </ul>
        </section>
        <hr class="dropdown-divider">
        <b-field class="chat-input">
          <b-input
            v-model="comment"
            placeholder="Type and enter to send as admin message"
            size="is-medium"
            rounded
            expanded
            @keyup.native.enter="sendComment"
          />
        </b-field>
      </div>
    </div>
    <b-modal
      :active.sync="isCardModalActive"
      has-modal-card
      scroll="keep"
    >
      <div class="card member-warp">
        <div class="card-content">
          <span v-if="memberList.length > 0">
            <div class="member-item-modal">
              <div
                v-for="member in memberList"
                :key="member.uid"
                class="member-item-list-modal"
              >
                <article class="media">
                  <figure class="media-left">
                    <router-link
                      class="image is-64x64 is-square"
                      :to="{path:`/users/${member.uid}`}"
                    >
                      <img
                        class="is-rounded"
                        :src="member.profile_image_url"
                        alt="profile image"
                        loading="lazy"
                      >
                    </router-link>
                  </figure>
                  <div class="media-content">
                    <div class="member-content">
                      <router-link :to="{path:`/users/${member.uid}`}">
                        <div class="display-name">
                          {{ member.display_name }}
                        </div>
                      </router-link>
                      <div class="small">
                        (@{{ member.tag_name }})
                      </div>
                    </div>
                  </div>
                  <div class="member-button">
                    <b-button
                      expanded
                      outlined
                      type="is-danger"
                      size="is-small"
                      @click="confirmKickAccount(member.uid) "
                    >
                      Kick
                    </b-button>
                  </div>
                </article>
              </div>
            </div>
          </span>
          <span v-else>
            <h3 class="has-text-centered">No member join in room</h3>
          </span>
        </div>
      </div>
    </b-modal>
  </section>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { formatCreatedTime, formatNumberCommas } from '@/utils'
import getLiveById from '@/graphql/getLiveById.gql'
import getUserStatus from '@/graphql/getUserStatus.gql'
import closeLiveRoomById from '@/graphql/closeLiveRoom.gql'

export default {
  name: 'LiveDetail',
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  apollo: {
    lives: {
      query: getLiveById,
      variables () {
        return {
          id: this.$route.params.id
        }
      },
      result ({ data, loading, networkStatus }) {
        if (data.lives[0]) {
          this.getUserStatus(data.lives[0].user_uid)
        }
      }
    }
  },
  data () {
    return {
      comment: '',
      members: 0,
      memberListModalActive: false,
      isCardModalActive: false,
      isScrollBottom: false,
      memberList: [],
      requestList: [],
      speakerList: [],
      roomMessage: [],
      processing: false,
      users: {
        active: true
      }
    }
  },
  computed: {
    ...mapGetters([
      'socket'
    ])
  },
  watch: {
    roomMessage: function (newData, oldData) {
      if (newData) {
        this.scrollToLastMessage()
      }
    }
  },
  async created () {
    if (this.data) {
      this.getUserStatus(this.data.user.uid)
    }
  },
  updated () {
    if (this.isScrollBottom) {
      this.scrollToLastMessage()
    }
  },
  async mounted () {
    if (this.socket.isConnected) {
      this.joinRoom()
    } else {
      await this.sleep(750)
      this.joinRoom()
    }
    this.$socket.onmessage = ({ data }) => {
      const ws = JSON.parse(data)
      // console.log(ws)
      switch (ws.event) {
        case 'room_members':
          this.members = ws.data
          break
        case 'member_changed':
          this.memberList = ws.data
          break
        case 'request_call_changed':
          this.requestList = ws.data
          break
        case 'call_member_changed':
          this.speakerList = ws.data
          break
        case 'room_message':
          this.roomMessage = ws.data
          break
        case 'new_message':
          this.concatNewMessage(ws.data)
          break
        case 'live_ended':
          this.displayRoomDialog(ws.data)
          break
        default:
          break
      }
    }
  },
  beforeDestroy () {
    this.leaveRoom()
  },
  methods: {
    formatCreatedTime (isotime) {
      return formatCreatedTime(isotime)
    },
    formatNumberCommas (number) {
      return formatNumberCommas(number)
    },
    fallbackProfileImage (e) {
      e.target.src = 'https://space.pillowcast.app/image/default/default.svg'
    },
    async getUserStatus (uid) {
      const result = await this.$apollo.query({
        query: getUserStatus,
        variables: { uid },
        fetchPolicy: 'network-only'
      })
      this.users = result.data.users[0]
    },
    getThumbnailImage (url) {
      console.log(url)
      if (url) {
        return url
      }
      return 'https://space.pillowcast.app/image/default/logo.svg'
    },
    getProfileThumbnail (url) {
      return {
        imgPath: 'https://space.pillowcast.app/image/default/logo.svg'
      }
    },
    joinRoom () {
      this.$socket.sendObj({
        event: 'join_room',
        hidden: true,
        room: parseInt(this.$route.params.id)
      })
    },
    leaveRoom () {
      this.$socket.sendObj({
        event: 'leave_room',
        room: parseInt(this.$route.params.id)
      })
    },
    kickAccount (uid) {
      this.$socket.sendObj({
        event: 'kick_room_member',
        uid: uid
      })
    },
    getRoomAudience () {
      this.$socket.sendObj({
        event: 'get_member'
      })
      this.isCardModalActive = true
    },
    displayRoomDialog (text) {
      this.$buefy.dialog.alert({
        title: this.lives[0].title + ' ได้สิ้นสุดการ Live',
        message: text.reason,
        type: 'is-info',
        hasIcon: true,
        ariaRole: 'alertdialog',
        ariaModal: true
      })
      this.$router.replace('/live')
    },
    async closeLiveRoom (id) {
      this.showSucessToast('Terminating room, please wait', 'is-info')
      this.$analytics.logEvent('close_live_room', { id, timestamp: new Date().toISOString() })
      Vue.prototype.$socket.sendObj({
        event: 'close_room',
        room: id,
        content: this.comment
      })
      this.$apollo.mutate({
        mutation: closeLiveRoomById,
        variables: {
          roomid: this.lives[0].id,
          timestamp: new Date().toISOString()
        },
        update: async (store, { data }) => {
          if (data.update_lives) {
            this.showSucessToast('Live room has been terminated', 'is-success')
            await this.sleep(1000)
            this.$router.replace('/live')
          }
        }
      })
    },
    confirmTerminateRoom (id) {
      this.$buefy.dialog.confirm({
        title: 'Terminate Room',
        message: 'Are you sure you want to <b>terminate</b> this room?',
        confirmText: 'Terminate',
        type: 'is-warning',
        hasIcon: true,
        onConfirm: () => this.closeLiveRoom(id)
      })
    },
    confirmDisableAccount (uid) {
      this.$buefy.dialog.confirm({
        title: 'Disable account',
        message: 'Are you sure you want to <b>disable</b> this account?',
        confirmText: 'Disable',
        type: 'is-danger',
        hasIcon: true,
        icon: 'ban',
        onConfirm: () => this.disableAccount(uid)
      })
    },
    confirmKickAccount (uid) {
      this.$buefy.dialog.confirm({
        title: 'Kick account',
        message: 'Are you sure you want to <b>kick</b> this account?',
        confirmText: 'Kick',
        type: 'is-danger',
        hasIcon: true,
        icon: 'ban',
        onConfirm: () => this.kickAccount(uid)
      })
    },
    async disableAccount (uid) {
      try {
        this.processing = true
        const result = await this.$disableAccount(uid)
        if (result) {
          this.showSucessToast('Disable account success', 'is-warning')
          this.getUserStatus(uid)
        }
      } catch (error) {
        this.showErrorToast(error)
      } finally {
        this.processing = false
      }
    },
    async enableAccount (uid) {
      try {
        this.processing = true
        const result = await this.$enableAccount(uid)
        if (result) {
          this.showSucessToast('Enable account success', 'is-success')
          this.getUserStatus(uid)
        }
      } catch (error) {
        this.showErrorToast(error)
      } finally {
        this.processing = false
      }
    },
    showSucessToast (message, type) {
      this.$buefy.toast.open({
        message,
        type,
        position: 'is-bottom'
      })
    },
    showErrorToast (result) {
      this.$buefy.toast.open({
        message: `Something's not good, ${result.message}`,
        position: 'is-bottom',
        type: 'is-danger'
      })
    },
    sendComment () {
      if (this.comment) {
        Vue.prototype.$socket.sendObj({
          event: 'new_message',
          admin: true,
          content: this.comment
        })
        this.comment = ''
      }
    },
    concatNewMessage (data) {
      this.roomMessage.push(data)
    },
    scrollToLastMessage () {
      const element = document.getElementById('chatPanel')
      if (element) {
        element.scrollTop = element.scrollHeight
      }
    },
    handleScroll (obj) {
      if (obj.target.scrollTop + obj.target.offsetHeight === obj.target.scrollHeight) {
        this.isScrollBottom = true
      } else {
        this.isScrollBottom = false
      }
    },
    sleep (ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    }
  }
}
</script>

<style lang="scss" scoped>
@media(hover: none) and (pointer: coarse) {
  .container {
    margin: 10px;
  }
}
.title {
  margin-bottom: 0.5rem;
}
.profile-image {
  border: 1px solid #29394d;
}
.member-warp {
  width: 500px;
}
.member-list {
  justify-content: space-around;
  // flex-wrap: wrap;
  width: 100%;
}
.message-list {
  width: 100%;
}
.member-item, .message-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
  .profile-link {
    align-items: center;
    .display-name {
      padding: 0 1rem;
    }
  }
  .content {
    flex-grow: 2;
    width: fit-content;
    margin: 0;
  }
  .created-time {
    flex-grow: 1;
  }
}
.live-section {
  margin: 2rem 0;
  border: 1px solid #29394d;
  border-radius: 8px;
  padding: 14px;
}
.return-link {
  float: right;
  padding: 1rem 1.5rem;
}
.live-background-container {
  margin: 1rem;
  padding: 0;

  img {
    width: 100%;
    max-height: 650px;
    border-radius: 8px;
    border: 1px solid #29394d;
  }
}
.live-detail-container {
  overflow: hidden;
}
.chat-panel {
  section {
    height: 500px;
    overflow: auto;
  }
  .chat-input {
    padding-top: 6px;
  }
}
.action-panel {
  padding-top: 16px;
}
.call-member-section {
  span {
    width: 100%;
    min-height: 88px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .call-member {
    padding: 0 10px;
  }
  p {
    margin: 0 auto;
  }
}
.follow {
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  height: 48px;
  border-radius: 24px;
  padding: 10px 25px;
  opacity: 0.8;
  background-image: linear-gradient(102deg, #6adec3, #29afbe);
}
.request-call {
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  height: 48px;
  border-radius: 24px;
  padding: 10px 25px;
  opacity: 0.8;
  background-image: linear-gradient(102deg, #fb9b51, #fbae46 96%);
}
.gift {
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  height: 48px;
  border-radius: 24px;
  padding: 10px 25px;
  opacity: 0.8;
  background-image: linear-gradient(102deg, #3023ae, #c86dd7 102%);
  .gift-image {
    height: 30px;
    vertical-align: middle;
  }
  .gift-amount {
    padding-left: 5px;
  }
  .gift-content {
    padding-left: 5px;
  }
}

.member-item-modal {
  .member-item-list-modal {
    width: 100%;
    padding: 0 0.5rem;
    @media screen and (max-width: 767px) {
      padding: 0;
    }
    .media {
      display: inline-flex;
      align-items: center;
      padding: 0.5rem 0;
      width: 100%;
    }
    .media-content {
      display: flex;
      align-items: center;
      margin-bottom: 0;
      width: 50%;
    }
    .media-right {
      display: flex;
      flex-grow: 1;
      justify-content: flex-end;
      margin-left: 0;
    }
    .member-button {
      display: flex;
      flex-grow: 1;
      margin-left: auto;
      align-items: center;
      justify-content: flex-end;
      .total-spending {
          display: flex;
        p {
          font-size: 1rem;
          font-weight: 500;
          line-height: 1.3;
          color: $font-color;
          @media screen and (max-width: 1023px) {
            font-size: 0.875rem;
            font-weight: 500;
          }
        }
        img {
        margin-right: 10px;
        @media screen and (max-width: 1023px) {
            margin-right: 5px;
          }
        }
      }
    }
    .member-content {
      word-break: break-word;
      vertical-align: middle;
      display: inline-block;
      min-width: 100px;
      padding-right: 10px;
      @media screen and (max-width: 767px) {
        min-width: 50px;
        // max-width: 110px;
        word-break: break-word;
      }
      .display-name {
        font-size: 1rem;
        line-height: 1.3;
        font-weight: 500;
      }
      .small {
        font-size: 0.75rem;
      }
    }
  }
}
</style>
